// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NK12H{margin-bottom:12px;padding:5px 8px;background-color:var(--wpp-grey-color-300)}.eyCtq{display:grid;grid-auto-rows:48px;grid-template-columns:230px auto}.TzROO{padding:13px 16px;border-bottom:1px solid var(--wpp-grey-color-400)}.Ocf3a{align-items:center;padding:8px 16px;border-bottom:1px solid var(--wpp-grey-color-300)}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/membersGroups/MembersGroups.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,eAAA,CACA,0CAAA,CAGF,OACE,YAAA,CACA,mBAAA,CACA,gCAAA,CAGF,OACE,iBAAA,CACA,iDAAA,CAGF,OACE,kBAAA,CACA,gBAAA,CACA,iDAAA","sourcesContent":[".infoBlock {\n  margin-bottom: 12px;\n  padding: 5px 8px;\n  background-color: var(--wpp-grey-color-300);\n}\n\n.membersGrid {\n  display: grid;\n  grid-auto-rows: 48px;\n  grid-template-columns: 230px auto;\n}\n\n.header {\n  padding: 13px 16px;\n  border-bottom: 1px solid var(--wpp-grey-color-400);\n}\n\n.cell {\n  align-items: center;\n  padding: 8px 16px;\n  border-bottom: 1px solid var(--wpp-grey-color-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlock": `NK12H`,
	"membersGrid": `eyCtq`,
	"header": `TzROO`,
	"cell": `Ocf3a`
};
export default ___CSS_LOADER_EXPORT___;
