// as gantt-task-react is not allowed to do anything like this and it's rely on a virtual scroll
import { MayBeNull } from '@wpp-open/core'
import { getDaysInMonth } from 'date-fns'
// we must add additional manipulations based on internal structure of the library
import { useCallback } from 'react'

export const useFocusToday = () => {
  return () => {
    setTimeout(() => {
      const currentDay = document.querySelector('.today')
      if (!currentDay) return

      currentDay.scrollIntoView({ inline: 'center', block: 'nearest' })
    }, 100)
  }
}

export const useShiftToday = (columnWidth: number) => {
  const refCallback = useCallback(
    (container?: MayBeNull<HTMLElement>) => {
      if (container) {
        const today = new Date()
        const maxDays = getDaysInMonth(today)
        const dayOfMonth = today.getDate()
        const dayOfWeek = today.getDay() === 0 ? 7 : today.getDay()

        const weekShift = Math.floor(((dayOfWeek - 1) * columnWidth) / (7 - 1))
        const monthShift = Math.floor(((dayOfMonth - 1) * columnWidth) / (maxDays - 1))

        container.style.setProperty('--timeline-week-shift', `${weekShift}px`)
        container.style.setProperty('--timeline-month-shift', `${monthShift}px`)
      }
    },
    [columnWidth],
  )

  return { refCallback }
}
