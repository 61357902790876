import {
  WppActionButton,
  WppIconAdd,
  WppIconDrag,
  WppIconTrash,
  WppLabel,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { useForm } from 'hooks/form/useForm'
import { FormValues, multiValuesSchema } from 'pages/settings/attributes/components/addAttributeModal/utils'
import { DragRow } from 'pages/settings/attributes/components/multiValues/DragRow'
import styles from 'pages/settings/attributes/components/multiValues/MultiValues.module.scss'

interface Props {
  initialValues?: string[] | null
}

interface MultiValuesFormValues {
  valueOptions: { value: string }[]
}

const VALUES_LIMIT = 100

const valuesToFlatArray = (valueOptions: MultiValuesFormValues['valueOptions']) =>
  valueOptions.map(({ value }) => value || '') ?? []

export function MultiValues({ initialValues }: Props) {
  const { t } = useTranslation()
  const { setValue: setAttributeValue, trigger: triggerAttribute } = useFormContext<FormValues>()

  const defaultValues: MultiValuesFormValues = useMemo(
    () => ({
      valueOptions:
        Array.isArray(initialValues) && initialValues.length > 0
          ? initialValues.map(value => ({ value }))
          : [{ value: '' }],
    }),
    [initialValues],
  )

  const form = useForm<MultiValuesFormValues>({
    defaultValues,
    validationSchema: multiValuesSchema(),
  })
  const { control, trigger, watch, getValues } = form

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'valueOptions',
  })

  // sync with outer form
  useEffect(() => {
    // 1. on mount
    setAttributeValue('valueOptions', valuesToFlatArray(getValues('valueOptions')))

    // 2. on values change
    const { unsubscribe } = watch(value => {
      const valueOptions = valuesToFlatArray(value.valueOptions as MultiValuesFormValues['valueOptions'])

      setAttributeValue('valueOptions', valueOptions)
      triggerAttribute('valueOptions')
    })
    return () => unsubscribe()
  }, [getValues, setAttributeValue, triggerAttribute, watch])

  const hasMultipleValues = fields.length > 1

  return (
    <FormProvider {...form}>
      <Flex direction="column" gap={8} data-testid="attribute-values-form">
        <WppLabel
          config={{
            text: t('project.settings.attributes_modal.form_values_label'),
            icon: 'wpp-icon-info',
            description: t('project.settings.attributes_modal.form_values_hint'),
          }}
          typography="s-strong"
        />
        <Flex direction="column" data-testid="values-container" gap={16}>
          <DndProvider data-testid="values-container" backend={HTML5Backend}>
            {fields.map((field, index) => (
              <DragRow key={field.id} id={field.id} index={index} onSwap={swap} isDragDisabled={!hasMultipleValues}>
                <Flex align="start" gap={8}>
                  <div className={styles.alignWrap}>
                    <WppIconDrag
                      data-testid="drag-value-button"
                      className={clsx(styles.iconDrag, { [styles.noDrag]: !hasMultipleValues })}
                      aria-disabled={!hasMultipleValues}
                    />
                  </div>

                  <FormInput
                    data-testid="value-input"
                    name={`valueOptions.${index}.value`}
                    className={styles.valueInput}
                    placeholder={t('project.settings.attributes_modal.form_value_placeholder')}
                    onWppBlur={() => trigger('valueOptions')}
                  />

                  <div className={styles.alignWrap}>
                    <WppActionButton
                      data-testid="remove-value-button"
                      slot="trigger-element"
                      variant="secondary"
                      onClick={() => remove(index)}
                      disabled={!hasMultipleValues}
                    >
                      <WppIconTrash slot="icon-start" />
                    </WppActionButton>
                  </div>
                </Flex>
              </DragRow>
            ))}
          </DndProvider>
        </Flex>

        <WppActionButton
          data-testid="add-value-button"
          slot="trigger-element"
          variant="secondary"
          className={styles.addBtn}
          onClick={() => append({ value: '' })}
          disabled={fields.length >= VALUES_LIMIT}
        >
          <WppIconAdd slot="icon-start" />
          {t('project.settings.attributes_modal.form_add_value_button')}
        </WppActionButton>
        <div />
      </Flex>
    </FormProvider>
  )
}
