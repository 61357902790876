import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useCss } from 'react-use'

import styles from 'pages/project/components/canvas/components/placeholder/Placeholder.module.scss'

type Props = { height?: MayBeNull<number> } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const Placeholder = ({ className, height, ...rest }: Props) => {
  const heightClass = useCss({
    height: height ? `${height}px` : 'auto',
  })

  return <div {...rest} className={clsx(styles.placeholder, className, heightClass)} data-testid="placeholder" />
}
