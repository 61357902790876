import { createUseQuery } from 'api/common/createUseQuery'
import { fetchGroupMembersApi } from 'api/tenant/fetchers/fetchGroupMembersApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useFetchGroupMembersApi = createUseQuery({
  queryKey: ApiQueryKeys.TENANT_GROUP_MEMBERS,
  fetcher: fetchGroupMembersApi,
  selector: res => {
    if (res?.data) {
      return res.data.map(member => {
        const { first_name, last_name, ...rest } = member
        return {
          firstname: first_name,
          lastname: last_name,
          ...rest,
        }
      })
    }

    return []
  },
})
