import { useMemo } from 'react'

export const useHaveAnyAttributes = (attributes: Record<string, any> = {}) => {
  return useMemo(
    () =>
      Object.entries(attributes)
        .filter(([, value]) => !!value.length)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    [attributes],
  )
}
