import { WppIconInfo, WppTypography } from '@platform-ui-kit/components-library-react'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TemplateDetail } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/projectModal/components/membersGroups/MembersGroups.module.scss'
import { SelectMemberInGroup } from 'pages/components/projectModal/components/membersGroups/SelectMemberInGroup'

export const MembersGroups = ({ template }: { template: TemplateDetail }) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex className={styles.infoBlock} align="center" gap={4}>
        <WppIconInfo />
        <WppTypography type="s-body">
          <Trans
            i18nKey="modals.create_project.members_groups.template_info"
            values={{ name: template.name }}
            components={{ bold: <WppTypography type="s-strong" /> }}
          />
        </WppTypography>
      </Flex>

      <div className={styles.membersGrid}>
        <WppTypography type="s-strong" className={styles.header}>
          {t('modals.create_project.members_groups.group')}
        </WppTypography>
        <WppTypography type="s-strong" className={styles.header}>
          {t('modals.create_project.members_groups.member')}
        </WppTypography>

        {template.groups!.map((group, idx) => (
          <Fragment key={group.id}>
            <WppTypography className={styles.cell} type="s-body">
              {group.name}
            </WppTypography>
            <div className={styles.cell}>
              <SelectMemberInGroup groupId={group.id} index={idx} />
            </div>
          </Fragment>
        ))}
      </div>
    </>
  )
}
