export enum TableKey {
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_FILE_LIST = 'PROJECT_FILE_LIST',
  TASKS_LIST = 'TASKS_LIST',
  TEMPLATE_TASKS_LIST = 'TEMPLATE_TASKS_LIST',
}

export enum TableDefaults {
  LoaderStaleTime = 60 * 1000,
  CacheBlockSize = 50,
}
