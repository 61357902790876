import { Identifier } from 'dnd-core'
import { PropsWithChildren, useRef } from 'react'
import { useDrag, useDrop, XYCoord } from 'react-dnd'

interface Props {
  id: string
  index: number
  isDragDisabled: boolean
  onSwap: (indexA: number, indexB: number) => void
}

export const ItemTypes = {
  InputRow: 'inputRow',
}

interface DragItem {
  index: number
  id: string
}

export function DragRow({ children, index, id, isDragDisabled, onSwap }: PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.InputRow,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onSwap(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.InputRow,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isDragDisabled,
  })

  drag(drop(ref))
  return (
    <div ref={ref} style={{ opacity: isDragging ? 0 : 1 }} data-handler-id={handlerId} data-testid="value-dragable-row">
      {children}
    </div>
  )
}
