import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TemplateDetail } from 'api/templates/fetchers/fetchWorkflowTemplateByIdApi'
import { useUpdateWorkflowTemplateApi } from 'api/templates/queries/usePatchWorkflowTemplateApi'
import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { TemplateTagControl } from 'pages/project/components/canvas/components/saveAsTemplateModal/TemplateTagControl'
import styles from 'pages/templates/components/createNewTemplateModal/CreateNewTemplateModal.module.scss'
import {
  VALIDATION,
  templateValidationSchema,
  ProjectTypeOption,
} from 'pages/templates/components/createNewTemplateModal/utils'
import { useTemplateAnalytics } from 'pages/templates/hooks/useTemplateAnalytics'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectType } from 'types/projects/projects'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'
import { is409Error } from 'utils/error'

interface Props extends NiceModalWrappedProps {
  template: TemplateDetail
}

const EditTemplateModal = ({ isOpen, onClose, onCloseComplete, template }: Props) => {
  const { t } = useTranslation()

  const { name, description, types, tags, processType } = template

  const { trackTemplateEdit, trackTemplateEditCancel } = useTemplateAnalytics()

  const defaultValues = useMemo(
    () => ({
      name,
      projectTypes: types,
      description: description || '',
      tags,
      processType,
    }),
    [description, name, processType, tags, types],
  )

  const form = useForm({ defaultValues, validationSchema: templateValidationSchema })
  const { showToast } = useToast()

  const { mutateAsync: updateTemplate } = useUpdateWorkflowTemplateApi()

  const typeOptions: ProjectTypeOption[] = useMemo(() => {
    return (Object.keys(ProjectType) as ProjectType[]).map(type => ({
      value: type,
      // keep it in sync with `modals.save_project_as_template` translation node
      label: t(`modals.save_project_as_template.project_type_${type.toLowerCase()}`),
    }))
  }, [t])

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
    trigger,
  } = form

  const onSubmit = handleSubmit(async () => {
    if (!isValid) {
      await trigger()
      return
    }
    handleSave()
  })

  const handleSave = async () => {
    try {
      const { name, description, projectTypes, tags } = getValues()
      await updateTemplate({
        id: template.id,
        name: name.trim(),
        types: projectTypes,
        description: description?.trim() || undefined,
        tags,
      })

      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])
      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_BY_ID])
      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])

      showToast({
        type: 'success',
        message: t('modals.edit_template_details.toast_update_message'),
      })

      trackTemplateEdit()
      onClose()
    } catch (e) {
      const is409 = is409Error(e)
      let message = t('modals.create_project.toast_error_common')

      if (is409) message = t('modals.edit_template_details.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
      console.error(e)
    }
  }

  const handleCancelEdit = () => {
    onClose()
    trackTemplateEditCancel()
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={handleCancelEdit}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="save-as-tpl-modal"
        >
          <WppTypography slot="header" type="2xl-heading">
            {t('modals.edit_template_details.title')}
          </WppTypography>
          <Flex slot="body" direction="column" gap={24} className={styles.container}>
            <FormInput
              name="name"
              placeholder={t('modals.create_new_template.field_name_placeholder')!}
              labelConfig={{ text: t('modals.create_new_template.field_name_label') }}
              required
              data-testid="save-as-tpl-modal-name"
            />

            <FormSelect
              name="projectTypes"
              type="multiple"
              options={typeOptions}
              placeholder={t('modals.create_new_template.field_project_placeholder')!}
              labelConfig={{ text: t('modals.create_new_template.field_project_label') }}
              data-testid="save-as-tpl-modal-type"
            />

            <FormTextareaInput
              name="description"
              warningThreshold={VALIDATION.description.max - 20}
              placeholder={t('modals.save_project_as_template.field_description_placeholder')!}
              labelConfig={{ text: t('modals.save_project_as_template.field_description_label') }}
              charactersLimit={VALIDATION.description.max}
              data-testid="save-as-tpl-modal-description"
            />

            <TemplateTagControl />
          </Flex>
          <Flex slot="actions" justify="end" gap={12}>
            <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
              {t('common.btn_cancel')}
            </WppButton>
            <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
              {t('common.btn_save')}
            </WppButton>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showEditTemplateModal } = createNiceModal<Props>(EditTemplateModal, 'edit-template-modal')
