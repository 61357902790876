import { MayBeNull } from '@wpp-open/core'

import { useField } from 'hooks/form/useField'
import { SelectOsGroup } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroup'
import { TenantGroup } from 'types/tenants/tenant'

interface Props {
  initialValue?: MayBeNull<TenantGroup>
  'data-testid'?: string
}

export const SelectOsGroupForm = ({ 'data-testid': dataTestId, initialValue }: Props) => {
  const {
    field: { value, onChange, onBlur },
  } = useField({
    name: 'groupId',
  })

  // Handle form value change
  const handleGroupChange = (groupId: string) => {
    if (groupId) {
      onChange(groupId)
    } else {
      onChange('')
    }
  }

  return (
    <SelectOsGroup
      value={value}
      initialValue={initialValue}
      onChange={handleGroupChange}
      className="custom-class"
      data-testid={dataTestId}
      onBlur={onBlur}
      name="groupId"
    />
  )
}
