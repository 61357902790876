import { UserDetails } from '@wpp-open/core'

import { facadeApi } from 'api'
import { Paginated } from 'types/common/utils'

export interface Params {
  limit?: number
  offset?: number
  search?: string
}

export type UsersListResponse = Paginated<UserDetails>

export const fetchUsersListApi = ({ offset = 0, limit = 20, search }: Params) =>
  facadeApi.get<UsersListResponse>('/users', {
    params: {
      offset,
      limit,
      'filter[search]': search,
      'filter[active]': 'true',
    },
  })
