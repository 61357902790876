// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wohB1{margin-bottom:12px;color:#000}.ckDUM{width:100%}.PX2hJ{flex-grow:1}.tLec9{color:var(--wpp-grey-color-800)}.YY5FS{text-wrap:balance;text-overflow:ellipsis}.BEyW6{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/membersRoles/MembersRoles.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,UAAA,CAGF,OACE,UAAA,CAGF,OACE,WAAA,CAGF,OACE,+BAAA,CAGF,OACE,iBAAA,CACA,sBAAA,CAGF,OACE,aAAA","sourcesContent":[".header {\n  margin-bottom: 12px;\n  color: #000000;\n}\n\n.row {\n  width: 100%;\n}\n\n.assignmentCard {\n  flex-grow: 1;\n}\n\n.email {\n  color: var(--wpp-grey-color-800);\n}\n\n.ellipsis {\n  text-wrap: balance;\n  text-overflow: ellipsis;\n}\n\n.noShrink {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `wohB1`,
	"row": `ckDUM`,
	"assignmentCard": `PX2hJ`,
	"email": `tLec9`,
	"ellipsis": `YY5FS`,
	"noShrink": `BEyW6`
};
export default ___CSS_LOADER_EXPORT___;
