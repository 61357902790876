import { MayBeNull } from '@wpp-open/core'

import { LowercaseHierarchyLevels, ProjectStatus } from 'types/projects/projects'
import { TenantGroup } from 'types/tenants/tenant'

export interface Task {
  id: string
  itemId: string
  targetType: TaskTargetType
  projectName: string
  projectId: string
  name: string
  description: string
  status: TaskStatus
  startDate?: string
  endDate?: string
  assignUser: string
  location: Location
  projectStatus: ProjectStatus
  createdAt: string
  wrike: MayBeNull<TaskWrike>
  priority: TaskPriority
  group?: MayBeNull<TenantGroup>
}

export interface TaskWrike {
  taskUrl: MayBeNull<string>
  externalStatus: MayBeNull<string>
  externalStatusId: MayBeNull<string>
  lastSyncedAt: MayBeNull<string>
}

export enum TaskTargetType {
  ACTIVITY = 'activity',
  APPLICATION = 'application',
  PHASE = 'phase',
  EXTERNAL = 'external',
}

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum TaskPriority {
  LOW = 'Low',
  NORMAL = 'Normal',
  HIGH = 'High',
}

export enum TaskWrikeStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
}

export interface Location {
  phase: MayBeNull<LocationItem>
  activity: MayBeNull<LocationItem>
  application: MayBeNull<LocationItem>
}

interface LocationItem {
  id: string
  name: string
  assignUser?: MayBeNull<string>
}

export enum TaskDueDate {
  OVERDUE = 'OVERDUE',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  NEXT_YEAR = 'NEXT_YEAR',
}

export interface TasksFilter extends LowercaseHierarchyLevels {
  search?: string
  dueDateRanges?: (keyof typeof TaskDueDate)[]
  selectedProjects?: string[]
  workspace?: string[]
  tenant?: string[]
  selectedStatuses?: string[]
  attributes?: Record<string, string[]>
  includeEmptyWorkspace?: boolean
}
