import {
  WppButton,
  WppInlineMessage,
  WppLabel,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'

import { useUpdateProjectViewSettingsApi } from 'api/projects/mutation/useUpdateProjectViewSettingsApi'
import { Flex } from 'components/common/flex/Flex'
import { FormToggle } from 'components/form/formToggle/FormToggle'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { LinearPreset } from 'pages/project/components/canvas/components/viewSettingsModal/presets'
import styles from 'pages/project/components/canvas/components/viewSettingsModal/ViewSettingsModal.module.scss'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { PresetType, ProjectPartKey, ProjectViewSettings } from 'types/projects/projectViewSettings'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {}

type FormValue = ProjectViewSettings

const visibleSettingsKeys = [
  ProjectPartKey.AppIcon,
  ProjectPartKey.ResponsiblePersons,
  ProjectPartKey.Descriptions,
  ProjectPartKey.Dates,
  ProjectPartKey.ActivityFilesAndLinks,
  ProjectPartKey.StatusBar,
] as const

const ViewSettingsModal = ({ isOpen, onClose, onCloseComplete }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const { viewSettings, project } = useProject()
  const form = useForm<FormValue>({ defaultValues: viewSettings })
  const [isDisabled, setIsDisabled] = useState(true)
  const lastCustomSettings = useRef(viewSettings)

  const { mutateAsync: updateProjectViewSettings } = useUpdateProjectViewSettingsApi()

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
    trigger,
    watch,
    getValues,
    setValue,
  } = form

  const preset = watch('preset')
  const prevPreset = usePrevious(preset)

  useEffect(() => {
    // store actual custom settings for the current session
    if (prevPreset === PresetType.CUSTOM && preset !== PresetType.CUSTOM) {
      lastCustomSettings.current = { ...getValues() }
    }

    if (preset !== PresetType.CUSTOM) {
      setIsDisabled(true)

      Object.entries(LinearPreset[preset]).forEach(([key, value]) => {
        setValue(key as ProjectPartKey, value)
      })
    } else {
      setIsDisabled(false)
      reset({ ...lastCustomSettings.current, preset: PresetType.CUSTOM })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset, reset, setValue, viewSettings])

  const onSubmit = handleSubmit(async () => {
    if (!isValid) {
      await trigger()
      return
    }

    try {
      const { id: preferenceId, ...settings } = getValues()
      await updateProjectViewSettings({
        projectId: project.id,
        preferenceId,
        settings,
      })
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_VIEW_SETTINGS])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.log(e)
    }
  })

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="s"
        data-testid="view-settings-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.workflow_settings.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={12}>
          <WppTypography type="s-strong">{t('modals.workflow_settings.presets')}</WppTypography>
          <Flex direction="column" gap={24}>
            <WppSegmentedControl
              value={getValues('preset')}
              onWppChange={event => setValue('preset', event.detail.value as PresetType)}
              data-testid="view-settings-presets"
            >
              <WppSegmentedControlItem value={PresetType.DEFAULT}>
                {t(`modals.workflow_settings.${PresetType.DEFAULT}`)}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={PresetType.PITCH}>
                {t(`modals.workflow_settings.${PresetType.PITCH}`)}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={PresetType.MINIMAL}>
                {t(`modals.workflow_settings.${PresetType.MINIMAL}`)}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={PresetType.CUSTOM}>
                {t(`modals.workflow_settings.${PresetType.CUSTOM}`)}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            {preset !== PresetType.DEFAULT && (
              <Flex>
                <WppInlineMessage
                  size="m"
                  message={t('modals.workflow_settings.custom_preset_warning')!}
                  type="information"
                  data-testid="custom-preset-warning"
                />
              </Flex>
            )}
          </Flex>

          <Flex direction="column" gap={20} className={styles.togglesContainer} data-testid="view-settings-toggles">
            {visibleSettingsKeys.map(key => (
              <Flex justify="between" key={key} data-testid="toggle-row">
                <WppLabel typography="s-body">{t(`modals.workflow_settings.${key}`)}</WppLabel>
                <FormToggle disabled={isDisabled} name={key} />
              </Flex>
            ))}
          </Flex>
          {preset !== PresetType.CUSTOM && (
            <WppTypography type="xs-body" className={styles.hint}>
              {t('modals.workflow_settings.hint')}
            </WppTypography>
          )}
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={() => onClose()} data-testid="cancel-settings">
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} data-testid="save-settings">
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}
export const { showModal: viewSettingsModal } = createProjectModal<Props>(ViewSettingsModal, 'view-settings-modal')
