import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteProjectMembersApi } from 'api/projects/mutation/useDeleteProjectMembersApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/members/components/removeMembersModal/RemoveMembersModal.module.scss'
import { Members } from 'types/members/members'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  projectId: string
  membersToDelete: Members[]
}

const RemoveMembersModal: FC<Props> = ({ isOpen, onClose, onCloseComplete, projectId, membersToDelete }) => {
  const { showToast } = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: deleteMembers, isLoading: isDeleting } = useDeleteProjectMembersApi()
  const { t } = useTranslation()

  const lastMember = membersToDelete.slice(-1)[0]
  const restOfMembers = membersToDelete.length >= 2 ? membersToDelete.slice(0, -1) : membersToDelete

  const handleDelete = async () => {
    try {
      const emails = membersToDelete.map(member => member.email)
      await deleteMembers({ projectId: projectId, emails })
      queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  }

  return (
    <Modal open={isOpen} onWppModalCloseComplete={onCloseComplete} onWppModalClose={onClose}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center" data-testid="delete-group-modal-title">
          {t(
            membersToDelete.length > 1
              ? 'modals.project_members.remove_members_label'
              : 'modals.project_members.remove_member_label',
          )}
        </Flex>
      </WppTypography>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        <span>{t('modals.project_members.remove_members')}</span>{' '}
        <span className={styles.boldText}>
          {restOfMembers.map(
            (member, index) =>
              `${member.firstname} ${member.lastname}${index !== restOfMembers.length - 1 ? ', ' : ''}`,
          )}
        </span>
        <span>
          {membersToDelete.length >= 2 && lastMember ? ` ${t('modals.project_members.remove_members_and')} ` : ''}
        </span>
        <span className={styles.boldText}>
          {membersToDelete.length >= 2 && lastMember ? ` ${lastMember.firstname} ${lastMember.lastname}` : ''}
        </span>{' '}
        <span>{t('modals.project_members.remove_members_project')}</span>
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton loading={isDeleting} onClick={handleDelete} size="s" variant="destructive">
          {t('common.btn_remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showRemoveMembersModal } = createNiceModal<Props>(RemoveMembersModal, 'remove-members-modal')
