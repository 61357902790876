import { WppListItem } from '@platform-ui-kit/components-library-react'

import { useAssignMember } from 'hooks/useAssignMember'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal.module.scss'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'
import { isTaskStatusResolved } from 'utils/project'

interface Props {
  activity: PhaseItem<ActivityItem>
  setSelectedItem: (appId: string | null, type: PhaseItemType) => void
}

export const PhaseDetailsModalActivity = ({ activity, setSelectedItem }: Props) => {
  const assignMember = useAssignMember(activity.item.assignUser)
  const isResolved = !!activity.item.task && isTaskStatusResolved(activity.item.task)

  const showTask = activity?.item.task.status === TaskStatus.COMPLETED
  const showCalendar = activity.item.startDate || activity.item.endDate

  const subBur = showTask ? (
    <div className={styles.dateWrapper}>
      <StatusText statusKey={activity.item.task?.status} small />
    </div>
  ) : showCalendar ? (
    <div className={styles.dateWrapper}>
      <Calendar
        startDate={activity.item.startDate}
        endDate={activity.item.endDate}
        hideIcon
        size="xs-body"
        resolved={isResolved}
      />
    </div>
  ) : null

  return (
    <WppListItem
      onWppChangeListItem={() => setSelectedItem(activity.item.id, PhaseItemType.Activity)}
      className={styles.appItem}
      data-testid="phase-details-activity-item"
    >
      <span slot="label" data-testid="phase-details-app-name">
        {activity.item.name}
      </span>

      <span slot="caption">{subBur}</span>

      <div slot="right">
        <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="task-item-assignee" />
      </div>
    </WppListItem>
  )
}
