import { createUseQuery } from 'api/common/createUseQuery'
import { fetchProjectsViewSettingsApi } from 'api/projects/fetchers/fetchProjectsViewSettingsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useFetchProjectsViewSettingsApi = createUseQuery({
  queryKey: ApiQueryKeys.PROJECTS_VIEW_SETTINGS,
  fetcher: fetchProjectsViewSettingsApi,
  selector: res => {
    return res?.data.data?.sort((a, b) => (a.orderNumber! > b.orderNumber! ? 1 : -1)) || []
  },
})
