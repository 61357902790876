// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IMW6q{height:calc(100vh - 180px);padding-bottom:24px;overflow:hidden}.l8LZa{width:100%;height:calc(100vh - 480px);padding-bottom:30px;overflow-x:auto}.Ujhez{grid-column:1/-1;padding-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CACA,mBAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,0BAAA,CACA,mBAAA,CACA,eAAA,CAGF,OACE,gBAAA,CACA,mBAAA","sourcesContent":[".container {\n  height: calc(100vh - 180px);\n  padding-bottom: 24px;\n  overflow: hidden;\n}\n\n.cardsWrapper {\n  width: 100%;\n  height: calc(100vh - 480px);\n  padding-bottom: 30px;\n  overflow-x: auto;\n}\n\n.nextPageSpinner {\n  grid-column: 1/-1;\n  padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IMW6q`,
	"cardsWrapper": `l8LZa`,
	"nextPageSpinner": `Ujhez`
};
export default ___CSS_LOADER_EXPORT___;
