import { WppTypography, WppCard } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/projectDashboard/ProjectDashboard.module.scss'
import { ProjectRole } from 'types/permissions/permissions'
import { fullName } from 'utils/common'

export const ProjectOwners = () => {
  const { t } = useTranslation()
  const { members } = useProject()

  const owners = useMemo(() => {
    return members
      .filter(member => member.role === ProjectRole.OWNER)
      .sort((a, b) => (a?.firstname || '').localeCompare(b?.firstname || ''))
  }, [members])

  return (
    <WppCard className={styles.detailsCard} size="l" data-testid="project-owners-card">
      <Flex direction="column" data-testid="project-members-info" gap={16}>
        <WppTypography slot="header" type="l-strong" className={styles.label}>
          {t('project.members_card.project_owners')}
        </WppTypography>
        <div className={styles.grid}>
          {owners.map(owner => (
            <Flex key={owner?.email} gap={8} align="center" data-testid="member-info-owner">
              <Avatar
                data-testid="member-avatar"
                className={styles.avatar}
                size="s"
                name={fullName(owner?.firstname, owner?.lastname)}
                src={owner?.avatarUrl ?? ''}
              />

              <Flex direction="column">
                <Truncate
                  lines={1}
                  type="s-strong"
                  title={fullName(owner?.firstname, owner?.lastname)}
                  data-testid="member-fullname"
                >
                  {fullName(owner?.firstname, owner?.lastname)}
                </Truncate>
                <Link to={`mailto: ${owner?.email}`} className={styles.emailIcon}>
                  <Truncate
                    lines={1}
                    type="s-body"
                    title={owner?.email}
                    className={styles.underline}
                    data-testid="member-email"
                  >
                    {owner?.email}
                  </Truncate>
                </Link>
              </Flex>
            </Flex>
          ))}
        </div>
      </Flex>
    </WppCard>
  )
}
