export type Unpacked<T> = T extends (infer U)[]
  ? U
  : T extends (...args: any[]) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T

export type ValueOf<T> = T[keyof T]

/**
 * Extracts object keys that have corresponding value types.
 *
 * Example:
 * interface Data {
 *   a: string
 *   b: number
 *   c: { d: string }
 * }
 * KeysWithMatchingValues<Data, string | number> => 'a' | 'b'
 */
export type KeysWithMatchingValues<T, V> = ValueOf<{ [K in keyof T]-?: T[K] extends V ? K : never }>

export interface Paginated<T> {
  data: T[]
  pagination: {
    limit: number
    offset: number
    total: number
  }
}

export type SortOrder = 'asc' | 'desc'

export type MayBeNull<T> = T | null

export enum DetailsModalType {
  PHASE_DETAILS_PREVIEW = 'phaseDetailsPreview',
  ACTIVITY_DETAILS_PREVIEW = 'activityDetailsPreview',
  APP_DETAILS_PREVIEW = 'appDetailsPreview',
  TASK_DETAILS_PREVIEW = 'taskDetailsPreview',
  FILE_DETAILS_PREVIEW = 'fileDetailsPreview',
  DASHBOARD_TASK_DETAILS_PREVIEW = 'dashboardTaskDetailsPreview',
  TEMPLATE_TASK_DETAILS_PREVIEW = 'templateTaskDetailsPreview',
}
