import {
  WppButton,
  WppDivider,
  WppIconInfo,
  WppIconMail,
  WppIconOrganization,
  WppLabel,
  WppTag,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateProjectMemberApi } from 'api/projects/mutation/useUpdateProjectMemberApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { SvgWrikeLogoRound } from 'components/svg/WrikeLogoRound'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/members/components/memberModal/MemberModal.module.scss'
import { roleOption } from 'pages/project/components/members/components/utils'
import { Members } from 'types/members/members'
import { Project } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { formatDate } from 'utils/dateFormat'

interface Props extends NiceModalWrappedProps {
  member: Members
  project: Project
  allOwnersSelected: boolean
}

const MemberModal = ({ member, onClose, isOpen, project, allOwnersSelected, onCloseComplete }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()

  const queryClient = useQueryClient()

  const { mutateAsync: updateProjectMember } = useUpdateProjectMemberApi()

  const form = useForm({ defaultValues: { role: member?.role } })
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async data => {
    const { role } = data

    if (role === member?.role) {
      onClose()
      return
    }

    try {
      await updateProjectMember({ role, memberId: member.id })
      await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  })

  const showTooltip = useMemo(
    () => member?.wrikeIntegrationStatus === 'CONNECTED' || member?.wrikeIntegrationStatus === 'NO_ACCOUNT',
    [member?.wrikeIntegrationStatus],
  )

  const showWrikeInfo = useMemo(() => member?.wrikeIntegrationStatus === 'CONNECTED', [member?.wrikeIntegrationStatus])

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="s"
        data-testid="view-member-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.members.title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={36} style={{ width: '100%' }}>
          <Flex gap={20}>
            <Avatar
              size="l"
              name={[member?.firstname, member?.lastname].join(' ').trim()}
              src={member?.avatarUrl ?? ''}
            />
            <Flex direction="column" justify="center" style={{ width: 'calc(100% - 20px - 64px)' }}>
              <Flex justify="between" align="center" gap={8}>
                <Truncate className={clsx({ [styles.userName]: member?.isExternal })} type="l-midi">
                  {[member?.firstname, member?.lastname].join(' ').trim()}
                </Truncate>
                {member?.isExternal && <WppTag label="External" variant="neutral" />}
              </Flex>
              <WppTypography type="s-body">{member?.jobTitle || ''}</WppTypography>
            </Flex>
          </Flex>
          <Flex direction="column" gap={24}>
            <Flex gap={20} align="center">
              <Flex direction="column" justify="center" align="center" className={styles.icon}>
                <WppIconMail />
              </Flex>
              <Flex direction="column">
                <WppTypography type="xs-midi">{t('modals.members.email')}</WppTypography>
                <WppTypography type="s-body">{member?.email}</WppTypography>
              </Flex>
            </Flex>
            <Flex gap={20} align="center">
              <Flex direction="column" justify="center" align="center" className={styles.icon}>
                <WppIconOrganization />
              </Flex>
              <Flex direction="column">
                <WppTypography type="xs-midi">{t('modals.members.organization')}</WppTypography>
                <WppTypography type="s-body">{member?.agency || ''}</WppTypography>
              </Flex>
            </Flex>
            {project.wrike?.isConnected && (
              <Flex gap={20} align="center">
                <Flex direction="column" justify="center" align="center" className={styles.icon}>
                  <SvgWrikeLogoRound />
                </Flex>
                <Flex direction="column">
                  <Flex gap={4} align="center">
                    <WppTypography type="xs-midi">{t('modals.members.wrike_account')}</WppTypography>
                    {showTooltip && (
                      <WppTooltip
                        header={showWrikeInfo ? t('modals.members.wrike_account_details') : ''}
                        config={{ trigger: showTooltip ? 'mouseenter' : 'manual', hideOnClick: false }}
                        className={styles.tipPyContent}
                        text={
                          showWrikeInfo
                            ? `${t('modals.members.wrike_email', { email: member?.wrikeEmail })}
                        ${t('modals.members.wrike_connected_at', {
                          date: formatDate({ dateString: member?.wrikeConnectedAt, dateLocale }),
                        })}`
                            : t('modals.members.wrike_no_account_info')
                        }
                      >
                        <WppIconInfo className={styles.infoIcon} />
                      </WppTooltip>
                    )}
                  </Flex>

                  <WppTypography type="s-body" className={styles[member.wrikeIntegrationStatus ?? '']}>
                    {member.wrikeIntegrationStatus
                      ? t(`modals.members.wrike_status.${member.wrikeIntegrationStatus}`, {
                          date: formatDate({ dateString: member?.wrikeInvitationSentAt, dateLocale }),
                        })
                      : t('modals.members.wrike_status.NO_ACCOUNT')}
                  </WppTypography>
                </Flex>
              </Flex>
            )}
          </Flex>
          <WppDivider />
          <Flex direction="column" gap={15}>
            <Flex direction="column">
              <WppTypography type="2xs-strong" className={styles.subTitle}>
                {t('modals.members.project')}
              </WppTypography>
              <WppTypography type="xl-heading">{project.name}</WppTypography>
            </Flex>

            <Flex direction="column" gap={6}>
              <WppLabel
                config={{
                  text: 'Access',
                  ...(allOwnersSelected
                    ? {
                        icon: 'wpp-icon-info',
                        description: t('modals.members.all_owners_selected')!,
                      }
                    : {}),
                }}
                htmlFor="role"
                tooltipConfig={{ trigger: allOwnersSelected ? 'mouseenter' : 'manual' }}
                typography="s-strong"
              />
              <FormSelect
                disabled={allOwnersSelected}
                required
                name="role"
                options={roleOption}
                data-testid="access-select"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showMemberModal } = createNiceModal<Props>(MemberModal, 'member-modal')
