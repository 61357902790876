import { SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppButton, WppIconPlus, WppInput, WppSkeleton, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useState, RefCallback, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { useDeleteWorkflowTemplateApi } from 'api/canvas/mutation/useDeleteWorkflowTemplateApi'
import { useFetchWorkflowTemplatesInfiniteApi } from 'api/templates/queries/useFetchWorkflowTemplatesInfiniteApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useTrackAction } from 'hooks/useAnalytics'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useToast } from 'hooks/useToast'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { TenantFilterSelect } from 'pages/dashboard/components/tenantFilterSelect/TenantFilterSelect'
import { useRemainingPaginationItems } from 'pages/project/hooks/useRemainingPaginationItems'
import { showCreateNewTemplateModal } from 'pages/templates/components/createNewTemplateModal/CreateNewTemplateModal'
import { useOpenTemplateModal } from 'pages/templates/hooks/useOpenTemplateModal'
import { useTemplateAnalytics } from 'pages/templates/hooks/useTemplateAnalytics'
import styles from 'pages/templates/Templates.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

export const Templates = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackTemplateDelete } = useTemplateAnalytics()
  const { trackAction } = useTrackAction()
  const [, setHideSubHeader] = useState(false)
  const {
    osContext: { userDetails, tenant },
  } = useOs()

  useOpenTemplateModal()

  const [search, setSearch] = useState<undefined | string>()
  const [tenantsFilter, setTenantsFilter] = useState<string[]>([])

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const {
    data: templates,
    response,
    isLoading: isTemplatesLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useFetchWorkflowTemplatesInfiniteApi({
    params: {
      inputText: search,
      tenant: tenantsFilter,
    },
  })

  const isFetching = isRefetching || isFetchingNextPage
  const paginator = response?.pages?.[response.pages.length - 1]?.data?.paginator

  const { itemsRemaining } = useRemainingPaginationItems({
    itemsPerPage: paginator?.itemsPerPage,
    page: paginator?.page,
    totalItems: paginator?.totalItems,
  })

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  useEffect(() => {
    const analyticsData = {
      user_agency: userDetails.agency,
      workspace_name: tenant.name,
      workspace_type: tenant.tenantType,
      filters_applied: !!tenantsFilter.length,
      search_appied: !!search,
    }

    trackAction(ACTION_ANALYTICS.ACTION_VIEW_TEMPLATES, analyticsData)
    // exclude other data, as we should track only event source and filters
  }, [search, tenant.name, tenant.tenantType, tenantsFilter.length, trackAction, userDetails.agency])

  const { isPermitted } = useIsPermitted()
  const isInternalUser =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const canCreateTemplate = isPermitted(AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE) || isInternalUser

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setSearch(searchQuery)
  }, 300)

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteTemplate } = useDeleteWorkflowTemplateApi()

  const handleDelete = async (id: string) => {
    try {
      await handleDeleteTemplate({ id })
      trackTemplateDelete()

      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      await queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES])
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  }

  const handleWorkspaceFilterChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setTenantsFilter(e.detail.value)
  }

  if (!isInternalUser) {
    return <Navigate to={routesManager.projects.root.getURL()} />
  }

  const message = search ? t('common.no_search_results') : t('templates.no_data')

  return (
    <Flex direction="column" className={styles.container}>
      <PageBackToTop onChangeState={setHideSubHeader} scrollTopOffset={150} />
      <DashboardNavigation />

      <Flex className={styles.cardContainer} direction="column" gap={24}>
        <Flex className={styles.filtersContainer} justify="between">
          <Flex gap={12}>
            <WppInput
              size="s"
              name="search"
              placeholder={t('dashboard.field_search_placeholder')!}
              onWppChange={e => setSearchDebounced(e.detail.value || '')}
              type="search"
              data-testid="dashboard-templates-search"
              className={styles.searchInput}
            />

            <TenantFilterSelect tenantIds={tenantsFilter} onChange={handleWorkspaceFilterChange} />
          </Flex>

          <Flex>
            {canCreateTemplate && !!templates.length && (
              <WppButton size="s" onClick={() => showCreateNewTemplateModal()} data-testid="create-new-template">
                <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_template')}
              </WppButton>
            )}
          </Flex>
        </Flex>
        {isTemplatesLoading || isRefetching ? (
          <div className={styles.cardsGrid} data-testid="templates-loading">
            {Array.from({ length: 6 }).map((_, index) => (
              <WppSkeleton key={index} variant="rectangle" height="203px" />
            ))}
          </div>
        ) : !templates.length ? (
          <EmptyState
            title={message}
            filtersApplied={!!search}
            description={!!search ? t('common.no_results_description') : t('templates.no_data_description')}
            testToken="templates"
          >
            {canCreateTemplate && (
              <WppButton size="m" onClick={() => showCreateNewTemplateModal()} data-testid="create-new-template">
                <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_template')}
              </WppButton>
            )}
          </EmptyState>
        ) : (
          <>
            <div className={styles.cardsGrid}>
              {templates.map(template => (
                <TemplateCard
                  key={template.id}
                  template={template}
                  withContextMenu
                  handleCreateFromTemplate={() => showCreateProjectModal({ templatePresetId: template.id })}
                  handleTemplateEdit={() => navigate(routesManager.template.canvas.getURL({ id: template.id }))}
                  handleRemoveTemplate={() =>
                    showDeleteModal({
                      title: t('template.delete_message_title'),
                      subTitle: t(
                        template.isPublished
                          ? 'template.delete_message_subtitle_published'
                          : 'template.delete_message_subtitle',
                      ),
                      onDelete: () => handleDelete(template.id),
                    })
                  }
                />
              ))}
              {isFetchingNextPage && (
                <>
                  {Array.from({ length: itemsRemaining }).map((_, index) => (
                    <WppSkeleton key={index} variant="rectangle" height="165px" />
                  ))}
                </>
              )}
            </div>
            <Flex justify="center" ref={setRef} className={styles.spinner}>
              {isTemplatesLoading && <WppSpinner size="l" />}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}
