import { useMemo } from 'react'

import { FormSelect } from 'components/form/formSelect/FormSelect'
import { AttributeProps, getFieldName } from 'pages/components/projectModal/components/attributes/utils'

export function MultiSelectAttribute({ attribute, formGroup, placeholder, isFilter }: AttributeProps) {
  const name = getFieldName(attribute, formGroup)
  const statusOptions = useMemo(() => {
    return (
      attribute.valueOptions?.map(option => ({
        value: option,
        label: option,
      })) ?? []
    )
  }, [attribute.valueOptions])

  return (
    <FormSelect
      name={name}
      type="multiple"
      data-testid={`modal-project-${attribute.contractName}`}
      options={statusOptions}
      placeholder={placeholder}
      labelConfig={{
        text: attribute.name,
        ...(attribute.iconText && {
          icon: 'wpp-icon-info',
          description: attribute.iconText,
        }),
      }}
      message={attribute.helperText}
      required={isFilter || (attribute.required ?? true)}
      disabled={attribute?.enabled === false}
    />
  )
}
