import { MayBeNull } from '@wpp-open/core'
import { endOfDay, isPast } from 'date-fns'

import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { Project, ProjectStatus } from 'types/projects/projects'
import { ProjectTimeline } from 'types/projects/projectsTimeline'
import { TaskStatus } from 'types/projects/tasks'
import { PhaseStatus } from 'types/projects/workflow'
import { routesManager } from 'utils/routesManager'

export interface ProjectTab {
  id: string
  value: string
  roles?: ProjectRole[]
  permissions?: AppPermissions[]
  forResponsible?: boolean
}

export const projectTabs: ProjectTab[] = [
  { id: routesManager.project.workflow.shortPattern, value: 'Workflow' },
  { id: routesManager.project.tasks.shortPattern, value: 'Tasks' },
  {
    id: routesManager.project.pinboard.shortPattern,
    value: 'Pinboard',
    permissions: [AppPermissions.ORCHESTRATION_PINBOARD_TAB_ACCESS],
  },
  { id: routesManager.project.timeline.shortPattern, value: 'Timeline' },
  { id: routesManager.project.files.shortPattern, value: 'Files' },
  { id: routesManager.project.members.shortPattern, value: 'Members' },
  { id: routesManager.project.overview.shortPattern, value: 'About' },
  { id: routesManager.project.canvas.shortPattern, value: 'Canvas', roles: [ProjectRole.OWNER], forResponsible: true },
]

export const templateTabs: ProjectTab[] = [
  { id: routesManager.template.canvas.shortPattern, value: 'Canvas' },
  { id: routesManager.template.tasks.shortPattern, value: 'Tasks' },
]

const isOverdue = ({ endDate }: { endDate?: MayBeNull<string> }): boolean => {
  return !!endDate && isPast(endOfDay(new Date(endDate)))
}

export const isTaskStatusResolved = ({ status }: { status: MayBeNull<TaskStatus> }) => {
  return status === TaskStatus.COMPLETED || status === TaskStatus.ARCHIVED
}

export const isPhaseStatusResolved = ({ status }: { status: MayBeNull<PhaseStatus> }) => {
  return status === PhaseStatus.COMPLETED
}
export const isProjectStatusResolved = ({ status }: { status: ProjectStatus }) => {
  return status === ProjectStatus.COMPLETED || status === ProjectStatus.ARCHIVED
}

export const isTaskOverdue = (task: { status: MayBeNull<TaskStatus>; endDate?: MayBeNull<string> }) => {
  return !isTaskStatusResolved(task) && isOverdue(task)
}

export const isPhaseOverdue = (phase: { status: MayBeNull<PhaseStatus>; endDate?: MayBeNull<string> }): boolean => {
  return !isPhaseStatusResolved(phase) && isOverdue(phase)
}

export const isProjectOverdue = (project: Project | ProjectTimeline): boolean => {
  return !isProjectStatusResolved(project) && isOverdue(project)
}
