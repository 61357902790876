import { ListValue, SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppInlineMessage, WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

export interface RenderProps<T> {
  option: T
  isSelected: boolean
  onDeselect: () => void
}

export interface TagProps {
  label: string
  variant?: 'warning' | 'neutral' | 'positive' | 'negative'
  categoricalColorIndex?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
}

export interface Props<T extends ListValue | undefined>
  extends Omit<ComponentPropsWithoutRef<typeof WppSelect>, 'onChange' | 'value' | 'onWppBlur'> {
  name: string
  options: T[]
  emptyValue?: any
  toggleSelection?: boolean
  infoMessage?: string
  getKey: (option: T) => string
  renderItem: (props: RenderProps<T>) => React.ReactNode
  renderPill: (props: RenderProps<T>) => React.ReactNode
}

export const MultipleSelectWithPill = <T extends ListValue | undefined>({
  name,
  options,
  infoMessage,
  dropdownPosition = 'fixed',
  emptyValue = '',
  toggleSelection,
  onWppChange,
  renderItem,
  renderPill,
  getKey,
  ...rest
}: Props<T>) => {
  const {
    field: { value, onChange },
  } = useField({ name })
  const innerRef = useRef<HTMLWppSelectElement>(null)

  const handlePillClose = (key: string) => {
    const newValue = value.filter((t: T) => getKey(t) !== key)
    onChange(newValue)
  }

  return (
    <Flex direction="column" gap={12}>
      <WppSelect
        {...rest}
        ref={innerRef}
        name={name}
        value={value}
        withFolder
        dropdownPosition={dropdownPosition}
        onWppChange={(e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
          onChange(e.detail.value)
          onWppChange?.(e)
        }}
        type="multiple"
      >
        {options.map(item => (
          <WppListItem
            key={getKey(item)}
            value={item}
            onWppChangeListItem={e => {
              const isDeselecting = value === e.detail.value
              if (toggleSelection && isDeselecting) {
                onChange(emptyValue)
                const event = new CustomEvent('onDeselect', {
                  detail: {
                    value: emptyValue,
                  },
                }) as WppSelectCustomEvent<SelectChangeEventDetail>
                Object.defineProperty(event, 'target', {
                  writable: false,
                  value: innerRef.current!,
                })
                onWppChange?.(event)
              }
            }}
          >
            {renderItem({
              option: item,
              isSelected: value.includes(item),
              onDeselect: () => handlePillClose(getKey(item)),
            })}
          </WppListItem>
        ))}
      </WppSelect>

      {!!value.length && (
        <Flex gap={5} direction="row" wrap="wrap">
          {value.map((t: T) =>
            renderPill({
              option: t,
              isSelected: value.includes(t),
              onDeselect: () => handlePillClose(getKey(t)),
            }),
          )}
        </Flex>
      )}

      {infoMessage && <WppInlineMessage type="information" message={infoMessage} data-testid="select-inline-message" />}
    </Flex>
  )
}
