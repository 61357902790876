import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Node } from 'reactflow'

import { usePatchFluidContainerApi } from 'api/canvas/mutation/usePatchFluidContainerApi'
import { CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import { NodeType } from 'pages/project/components/canvas/fluidCanvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { FluidWorkflow } from 'types/projects/workflow'

export function useUpdateFluidContainer({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const projectContext = useProject()

  const { trackItemMove } = useWorkflowEditAnalytics()

  const { mutateAsync: handlePatchContainer, isLoading } = usePatchFluidContainerApi({
    onMutate: async updatedContainer => {
      const projectFluidQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }]
      await queryClient.cancelQueries(projectFluidQueryKey)

      const previousFluid = queryClient.getQueryData<{ data: FluidWorkflow }>(projectFluidQueryKey)

      if (previousFluid) {
        const updatedData = {
          ...previousFluid.data,
          containers: previousFluid.data.containers.map(container => {
            if (container.id === updatedContainer.containerId) {
              return {
                ...container,
                coordinateX: updatedContainer.coordinateX,
                coordinateY: updatedContainer.coordinateY,
              }
            }

            return container
          }),
        }

        const newData = { ...previousFluid, data: updatedData }

        queryClient.setQueryData(projectFluidQueryKey, newData)
      }

      return { previousFluid }
    },
    onError: (_error, _, context) => {
      if (context?.previousFluid) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }], context.previousFluid)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    },
  })

  const updateContainer = useCallback(
    async (droppedNode: Node) => {
      const {
        id,
        position: { x, y },
        data: {
          oldPosition: { x: oldX, y: oldY },
        },
      } = droppedNode

      if (x === oldX && y === oldY) return

      try {
        await handlePatchContainer({
          containerId: id,
          coordinateX: x,
          coordinateY: y,
        })

        droppedNode.data.oldPosition.x = x
        droppedNode.data.oldPosition.y = y

        if (projectContext) {
          trackItemMove(droppedNode.type === NodeType.APP_NODE ? CanvasItemType.APPLICATION : CanvasItemType.ACTIVITY)
        }
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
      }
    },
    [handlePatchContainer, projectContext, showToast, t, trackItemMove],
  )

  return { updateContainer, isLoading }
}
