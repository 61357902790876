import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { ProjectAttributeClass } from 'types/projects/attributes'

export interface ProjectViewSettings {
  id: string
  classification: ProjectAttributeClass
  name: string
  enabled: boolean
  orderNumber: MayBeNull<number>
}

export const fetchProjectsViewSettingsApi = () =>
  projectApi.get<{
    data: ProjectViewSettings[]
  }>('/project-attribute-view-settings/')
