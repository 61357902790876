import { MayBeNull, Tenant } from '@wpp-open/core'
import { endOfDay, startOfDay } from 'date-fns'
import { Task as GanttTask, ViewMode } from 'gantt-task-react'

import { ProjectShortPhaseDTO, ProjectStatus } from 'types/projects/projects'
import { ProjectTimeline, ProjectTimelinePhase } from 'types/projects/projectsTimeline'
import { TaskDueDate } from 'types/projects/tasks'
import { PhaseStatus } from 'types/projects/workflow'
import { isPhaseOverdue, isProjectOverdue, isTaskOverdue } from 'utils/project'

const Colors = {
  default: 'var(--wpp-dataviz-color-seq-brand-200)',
  complete: 'var(--wpp-dataviz-color-seq-positive-200)',
  overdue: 'var(--wpp-dataviz-color-seq-negative-200)',
  archived: 'var(--wpp-dataviz-color-seq-grey-100)',
  transparent: 'transparent',
}

export interface TimelineFilters {
  search?: string
  viewMode: ViewMode
  dueDateRanges?: (keyof typeof TaskDueDate)[]
  selectedStatuses?: string[]
  assignee?: string[]
}

export type GanttTaskWithTenant = GanttTask & {
  tenant: Tenant
}

export const projectsTimeline2Gantt = ({
  timeline,
}: {
  timeline: MayBeNull<ProjectTimeline[]>
}): GanttTaskWithTenant[] => {
  if (!timeline) return []

  return timeline.map((project): GanttTaskWithTenant => {
    const isOverdue = isProjectOverdue(project)
    const isAnyChildOverdue = project.phases.some(isPhaseOverdue) || project.tasks.some(isTaskOverdue)
    const color =
      //archived grey, completed green, overdue red, default primary
      project.status === ProjectStatus.ARCHIVED
        ? Colors.archived
        : project.status === ProjectStatus.COMPLETED
        ? Colors.complete
        : isAnyChildOverdue || isOverdue
        ? Colors.overdue
        : Colors.default

    // project own start date or the earliest task start date
    const startDate = project.startDate || project.earliestSubitemDate

    // project own end date or the latest task start date
    const endDate = project.endDate
      ? project.endDate
      : [...project.phases, ...project.tasks]
          ?.filter(({ endDate }) => !!endDate)
          .map(({ endDate }) => endDate)
          .sort((a, b) => new Date(b!).getTime() - new Date(a!).getTime())[0]

    const isNoDates = !startDate || !endDate
    const transparentColor = Colors.transparent

    return {
      id: project.id,
      name: '',
      start: !isNoDates ? startOfDay(new Date(startDate!)) : new Date(),
      end: !isNoDates ? endOfDay(new Date(endDate!)) : new Date(),
      type: 'project',
      progress: 100,
      hideChildren: true,
      isDisabled: true,
      tenant: project.tenant,
      styles: {
        backgroundColor: transparentColor,
        backgroundSelectedColor: isNoDates ? transparentColor : color,
        progressColor: isNoDates ? transparentColor : color,
        progressSelectedColor: isNoDates ? transparentColor : color,
      },
    }
  })
}

export const getActivePhases = <T extends ProjectShortPhaseDTO | ProjectTimelinePhase>(phases: T[]): T[] =>
  phases.filter(phase => phase.status === PhaseStatus.IN_PROGRESS)
