import {
  WppCard,
  WppTypography,
  WppSkeleton,
  WppDivider,
  WppSegmentedControlItem,
  WppSegmentedControl,
} from '@platform-ui-kit/components-library-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchDashboardDataApi } from 'api/projects/queries/useFetchDashboardDataApi'
import { Flex } from 'components/common/flex/Flex'
import { ChartEntry, PieChartProgress } from 'components/common/pieChartProgress/PieChartProgress'
import { useProject } from 'hooks/useProject'
import { EmptyPieChart } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/pieChart/EmptyPieChart'
import { PieChart } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/pieChart/PieChart'
import { ScaleChart } from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/scaleChart/ScaleChart'
import { DashboardOwnership } from 'types/projects/DashboardData'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus, TaskWrikeStatus } from 'types/projects/tasks'

const Skeleton = () => <WppSkeleton variant="rectangle" height="100%" width="100%" />

export const TasksStatusBoard = () => {
  const { t } = useTranslation()
  const { project, useExternalStatuses } = useProject()
  const [subset, setSubset] = useState<DashboardOwnership>(DashboardOwnership.ALL)

  const { data: dashboardData, isLoading } = useFetchDashboardDataApi({
    params: { projectId: project.id, subset },
    enabled: !!project.id,
  })

  const countTasksByStatus = useMemo(() => {
    const statusCounts: Record<TaskStatus, number> = {
      [TaskStatus.TO_DO]: 0,
      [TaskStatus.IN_PROGRESS]: 0,
      [TaskStatus.COMPLETED]: 0,
      [TaskStatus.ARCHIVED]: 0,
    }

    dashboardData?.allTasks.forEach(task => statusCounts[task.status]++)

    const { ARCHIVED, ...rest } = statusCounts

    return rest
  }, [dashboardData])

  const getCompletedTasks = useMemo(() => {
    const completedTasks = dashboardData?.allTasks.filter(task => task.status === TaskStatus.COMPLETED)

    const statusCounts: Record<TaskWrikeStatus, ChartEntry> = {
      [TaskWrikeStatus.COMPLETED]: {
        value: completedTasks?.length || 0,
        color: 'var(--wpp-dataviz-color-seq-brand-500)',
        order: 1,
      },
      [TaskWrikeStatus.NOT_COMPLETED]: {
        value: (dashboardData?.allTasks.length || 0) - (completedTasks?.length || 0),
        order: 2,
      },
    }

    return statusCounts
  }, [dashboardData])

  const isEmptyAll = dashboardData?.allTasks.length === 0
  const isEmptyByPhases = useMemo(
    () => dashboardData?.phases.every(phase => !phase.tasks.length),
    [dashboardData?.phases],
  )

  if (isLoading) return <Skeleton />

  return (
    <WppCard data-testid="task-status-widget">
      <WppTypography slot="header" type="l-strong" data-testid="about-task-progress">
        {project.wrike?.isConnected ? t('overview.chart.tasks_completed') : t('overview.chart.task_progress')}
      </WppTypography>

      <WppSegmentedControl
        slot="actions"
        value={subset}
        size="s"
        onWppChange={e => {
          setSubset(e.target.value as DashboardOwnership)
        }}
        data-testid="tasks-all-my"
      >
        <WppSegmentedControlItem value={DashboardOwnership.ALL} data-testid="tasks-all-segment">
          {t('overview.chart.switch_all')}
        </WppSegmentedControlItem>
        <WppSegmentedControlItem value={DashboardOwnership.MY} data-testid="tasks-my-segment">
          {t('overview.chart.switch_my_task')}
        </WppSegmentedControlItem>
      </WppSegmentedControl>

      <Flex direction="column" gap={24}>
        {!!dashboardData && isEmptyAll ? (
          <EmptyPieChart />
        ) : useExternalStatuses ? (
          <PieChartProgress
            height={236}
            width={236}
            chartData={getCompletedTasks}
            progressBy={TaskWrikeStatus.COMPLETED}
          />
        ) : (
          <PieChart
            pieName={
              subset === DashboardOwnership.ALL
                ? t('overview.chart.pie_all_tasks')!
                : t('overview.chart.switch_my_task')!
            }
            height={236}
            width={236}
            tasksCount={countTasksByStatus}
          />
        )}
        {!useExternalStatuses && project.processType === ProcessType.LINEAR && !isEmptyByPhases && (
          <Flex direction="column" gap={16}>
            <WppDivider />

            <Flex direction="column">
              <WppTypography type="m-strong">{t('overview.chart.title')}</WppTypography>
              <WppTypography type="s-body">{t('overview.chart.subtitle')}</WppTypography>
            </Flex>

            <Flex direction="column" gap={8}>
              {dashboardData?.phases.map(phase =>
                phase.tasks.length ? <ScaleChart phase={phase} key={phase.id} /> : null,
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </WppCard>
  )
}
