import { WppActionButton, WppButton, WppIconGear, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { useProject } from 'hooks/useProject'
import { useProjectRole } from 'hooks/useProjectRole'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { showEditPhaseModal } from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModal.module.scss'
import { PhaseDetailsModalHeader } from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModalHeader'
import { PhaseDetailsModalTabs } from 'pages/project/components/canvas/linearCanvas/components/phase/detailsModal/PhaseDetailsModalTabs'
import { LinearPhase } from 'pages/project/components/canvas/utils'
import { ActivityItem, ApplicationItem, Phase, PhaseItem, PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

export enum PhaseTabsTypes {
  activities = 'activities',
  apps = 'apps',
}

export const phaseTabs = [
  { id: PhaseTabsTypes.activities, value: 'modals.phase_details.tabs.activities' },
  { id: PhaseTabsTypes.apps, value: 'modals.phase_details.tabs.apps' },
]

interface Props {
  phase: Phase | null
  setSelectedItemCallback: (id: string | null, type: PhaseItemType | null) => void
  isLoading?: boolean
  isError?: boolean
  error?: AxiosError<unknown, any> | null
  onClose: () => void
  projectId: string
  isWrikeConnected?: boolean
}

export const PhaseDetailsModalContent = ({
  onClose,
  phase,
  isLoading,
  isError,
  error,
  setSelectedItemCallback,
  projectId,
  isWrikeConnected,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isInactive } = useProject()
  const {
    osContext: { userDetails },
  } = useOs()

  const { isOwnerOrGlobalManage } = useProjectRole()

  const isMeAssignToThisPhase = useMemo(
    () => isEqualEmails(userDetails.email, phase?.assignUser),
    [phase?.assignUser, userDetails.email],
  )

  const canEdit = !isInactive && (isOwnerOrGlobalManage || isMeAssignToThisPhase)

  const phaseActivities = useMemo(() => {
    return phase?.phaseItems.filter(item => item.itemType === PhaseItemType.Activity) as PhaseItem<ActivityItem>[]
  }, [phase?.phaseItems])

  const phaseApps = useMemo(() => {
    return phase?.phaseItems.filter(item => item.itemType === PhaseItemType.Application) as PhaseItem<ApplicationItem>[]
  }, [phase?.phaseItems])

  const filteredTabs = useMemo(() => {
    if (!phase) return []

    const tabConditions: Record<PhaseTabsTypes, boolean> = {
      activities: !!phaseActivities?.length,
      apps: !!phaseApps?.length,
    }

    return phaseTabs.filter(({ id }) => {
      return id in tabConditions ? tabConditions[id] : true
    })
  }, [phase, phaseActivities?.length, phaseApps?.length])

  return (
    <>
      {isLoading ? (
        <SideModalContentSkeleton />
      ) : (
        <>
          <WppTypography slot="header" type="2xl-heading" data-testid="phase-name">
            {phase?.name ?? t('modals.phase_details_modal.title')}
          </WppTypography>

          <Flex slot="body" direction="column" gap={16} className={styles.detailsContainer}>
            {isError ? (
              <ErrorState error={error} />
            ) : (
              <>
                {phase && <PhaseDetailsModalHeader phase={phase} />}
                {!!filteredTabs.length && (
                  <PhaseDetailsModalTabs
                    tabs={filteredTabs}
                    activities={phaseActivities}
                    apps={phaseApps}
                    setSelectedItem={setSelectedItemCallback}
                  />
                )}
              </>
            )}
          </Flex>

          <Flex slot="actions" justify="end" gap={12}>
            <Flex justify="between" className={styles.actionsWrapper}>
              {canEdit && phase ? (
                <WppActionButton
                  onClick={() => {
                    onClose()
                    showEditPhaseModal({
                      phase: phase as unknown as LinearPhase,
                      isDisabled: isInactive,
                      onClose: () => navigate(-1),
                      projectId,
                      isWrikeConnected,
                    })
                  }}
                  data-testid="settings-action"
                  variant="primary"
                >
                  <WppIconGear slot="icon-start" />
                  {t('project.item.settings')}
                </WppActionButton>
              ) : (
                <div />
              )}

              <WppButton variant="primary" size="m" onClick={onClose}>
                {t('common.btn_done')}
              </WppButton>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}
