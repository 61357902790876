import { WppIconCalendarError } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'

import { ACTION_ANALYTICS, CanvasItemType, EventPageSource } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { getDate } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdatePhase } from 'pages/project/components/canvas/hooks/useUpdatePhase'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useUpdateTaskItem } from 'pages/project/components/tasks/hooks/useUpdateTaskItem'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { Project } from 'types/projects/projects'
import { isPhase, TimelinePhase, TimelineTask } from 'types/projects/timeline'
import { projectDateFormat } from 'utils/common'

interface Props {
  project: Project
  timelineItem: TimelineTask | TimelinePhase
}

const CalendarIcon = () => <WppIconCalendarError color="var(--wpp-danger-color-500)" />

export const TimelineDateInline = ({ project, timelineItem }: Props) => {
  const { updatePhase } = useUpdatePhase({ phaseId: timelineItem.id, projectId: project.id })
  const { updateItem } = useUpdateTaskItem({ filters: { projectId: project.id } })
  const { me } = useHasProjectRole()
  const { trackAction } = useTrackAction()

  const updateTaskDates = async (dates: Date[]) => {
    const stringDates = dates.map(date => format(date, projectDateFormat))

    const datesMapped = { ...getDate(stringDates) }

    await updateItem({ id: timelineItem.id, ...datesMapped })
    await invalidateCanvas()
  }

  const handleDatesChange = (dates: Date[]) => {
    const isPhaseItem = isPhase(timelineItem)
    if (isPhaseItem) {
      try {
        updatePhase({ dates, name: timelineItem.name })
        return
      } catch (error) {
        console.error('Error updating phase dates', error)
      }
    }

    updateTaskDates(dates)

    const analyticsData = {
      ...getCommonProjectAnalyticsData(project, me),
      type: isPhaseItem ? CanvasItemType.PHASE : CanvasItemType.APPLICATION,
      event_source: EventPageSource.TIMELINE,
    }

    trackAction(ACTION_ANALYTICS.ACTION_WORKFLOW_ITEM_DATES_EDIT, analyticsData)
  }

  return (
    <SelectDateInline
      calendarIcon={CalendarIcon}
      startDate={timelineItem.startDate!}
      endDate={timelineItem.endDate!}
      resolved={false}
      onChange={handleDatesChange}
    />
  )
}
