import { projectApi } from 'api'
import { TemplateTask } from 'types/templates/tasks'

export interface TasksParams {
  id: string
  text?: string
  groupId?: string
}

export const fetchTemplateTasksListApi = ({ id, text, groupId }: TasksParams) =>
  projectApi.post<TemplateTask[]>(`/projects/${id}/tasks/search`, {
    ...(text?.length && { text }),
    groupId,
  })
