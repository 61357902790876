import { WppIconUser, WppTooltip } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import styles from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson.module.scss'
import { Members } from 'types/members/members'
import { fullName } from 'utils/common'

//pick props from Members
type Member = Pick<
  Members,
  'id' | 'firstname' | 'lastname' | 'avatarUrl' | 'wrikeExternalFirstName' | 'wrikeExternalLastName'
>
interface Props {
  assignMember?: Member
  isPreviewMode?: boolean
  hideIcon?: boolean
  showName?: boolean
  customIcon?: React.ReactNode
  size?: 'xs' | 's'
}

export const ResponsiblePerson = ({ assignMember, hideIcon, size, showName, customIcon }: Props) => {
  const { t } = useTranslation()

  const firstName = assignMember?.firstname || assignMember?.wrikeExternalFirstName
  const lastName = assignMember?.lastname || assignMember?.wrikeExternalLastName

  return (
    <WppTooltip
      header={t('project.phase.responsible_person_tooltip_text')!}
      className={styles.tooltip}
      config={{ maxWidth: 'none', trigger: showName ? 'manual' : 'mouseenter', hideOnClick: false }}
      text={assignMember ? `${firstName} ${lastName}` : t('project.phase.responsible_person_not_set')!}
    >
      {assignMember ? (
        <Flex gap={4} className={styles.avatarFilled}>
          <Flex>
            <Avatar
              size={size ?? 's'}
              key={assignMember.avatarUrl}
              src={assignMember.avatarUrl ?? ''}
              name={`${firstName} ${lastName}`}
              data-testid={`responsible-person-${assignMember.id}`}
            />
          </Flex>

          {showName && (
            <Truncate type="s-strong" lines={1} data-testid="responsible-person-name">
              {fullName(firstName, lastName)}
            </Truncate>
          )}
        </Flex>
      ) : (
        !hideIcon &&
        (customIcon ? (
          <span className={styles.avatarEmpty}>{customIcon}</span>
        ) : (
          <WppIconUser className={styles.avatarEmpty} />
        ))
      )}
    </WppTooltip>
  )
}
