import { HierarchyLevelType } from '@wpp-open/core'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormSelect } from 'components/form/formSelect/FormSelect'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useHierarchyFilters } from 'pages/dashboard/utils/hierarchyFilters'
import { capitalizeFirstLetter, WITHOUT_HIERARCHY } from 'utils/common'

const locale = {
  emptyText: 'Nothing Found',
  clearAllText: 'Clear All',
  selectAllText: 'Select All',
  searchInputPlaceholder: 'Search',
  allSelectedText: 'All selected',
  selectLabel: 'selected',
}
export const HierarchyFilterControl = () => {
  const { t } = useTranslation()

  const { setValue, getValues } = useFormContext()
  const { hierarchyOrder } = useHierarchy()
  const { options } = useHierarchyFilters()

  const [disabled, setDisabled] = useState<boolean[]>(() => {
    const values = getValues()
    let foundWithout = false
    return hierarchyOrder.map(hierarchy => {
      if (foundWithout) return true
      if (values[hierarchy]?.includes(WITHOUT_HIERARCHY) && values[hierarchy].length === 1) foundWithout = true
      return false
    })
  })

  return hierarchyOrder?.map((hierarchy, index) => {
    const op = options?.[hierarchy as HierarchyLevelType] || []
    const allSelectedText = op.length === 1 ? op[0].label : 'All selected'

    return (
      <FormSelect
        key={hierarchy}
        name={hierarchy}
        withSearch
        className={styles.hierarchySelect}
        type="multiple"
        data-testid={`${hierarchy.toLowerCase()}-select`}
        options={[
          {
            value: WITHOUT_HIERARCHY,
            label: t('modals.dashboard_filters.without_hierarchy', { hierarchy: hierarchy.toLowerCase() }),
          },
          ...op,
        ]}
        labelConfig={{
          text: t(`modals.dashboard_filters.field_${hierarchy.toLowerCase()}_label`, {
            defaultValue: capitalizeFirstLetter(hierarchy),
          }),
        }}
        placeholder={
          t(`modals.dashboard_filters.field_${hierarchy.toLowerCase()}_placeholder`, {
            defaultValue: capitalizeFirstLetter(hierarchy),
          })!
        }
        locales={{
          ...locale,
          allSelectedText,
        }}
        disabled={disabled[index]}
        onWppChange={e => {
          if (e.detail.value.includes(WITHOUT_HIERARCHY) && e.detail.value.length === 1) {
            for (let i = index + 1; i < hierarchyOrder.length; i++) {
              setValue(hierarchyOrder[i], [])
              setDisabled(prev => prev.map((_, idx) => idx > index))
            }
          } else {
            setDisabled(prev => prev.map(() => false))
          }
        }}
        withFolder
        required
      />
    )
  })
}
