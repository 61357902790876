import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import 'app/global.module.scss'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { NotFound } from 'pages/404/NotFound'
import { MiroAuthCallback } from 'pages/components/projectModal/components/integrations/miro/MiroAuthCallback'
import { TasksDashboardPage } from 'pages/dashboard/components/tasksDashboard/TasksDashboard'
import { DashboardPage } from 'pages/dashboard/Dashboard'
import { CanvasTab } from 'pages/project/components/canvas/CanvasTab'
import { Files } from 'pages/project/components/files/Files'
import { MembersTab } from 'pages/project/components/members/Members'
import { Pinboard } from 'pages/project/components/pinboard/Pinboard'
import { ProjectDashboard } from 'pages/project/components/projectDashboard/ProjectDashboard'
import { Tasks } from 'pages/project/components/tasks/Tasks'
import { Timeline } from 'pages/project/components/timeline/Timeline'
import { Workflow } from 'pages/project/components/workflow/Workflow'
import { Project } from 'pages/project/Project'
import { ProjectPage } from 'pages/project/ProjectPage'
import { ProjectDemo } from 'pages/projectDemo/ProjectDemo'
import { Settings } from 'pages/settings/Settings'
import { CanvasTemplate } from 'pages/template/CanvasTemplate'
import { TemplateTasks } from 'pages/template/tasks/TemplateTasks'
import { Template } from 'pages/template/Template'
import { TemplatePage } from 'pages/template/TemplatePage'
import { Templates } from 'pages/templates/Templates'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

const PermittedAccess = ({ permission }: { permission: AppPermissions | AppPermissions[] }) => {
  const { isPermitted } = useIsPermitted()
  return isPermitted(permission) ? <Outlet /> : <Navigate replace to={routesManager['404'].root.pattern} />
}

export function App() {
  const { osContext } = useOs()

  // @TODO: Remove after fix WPPLONOP-12848
  if (!osContext.baseUrl) return null

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <NiceModalProvider>
        <Routes>
          <Route index element={<Navigate to={routesManager.projects.root.pattern} />} />

          <Route path={`${routesManager.projects.root.pattern}/*`} element={<DashboardPage />} />
          <Route path={routesManager.miroAuthCallback.pattern} element={<MiroAuthCallback />} />

          <Route path={routesManager.project.root.pattern} element={<ProjectPage />}>
            <Route path={routesManager.project.demo.shortPattern} element={<ProjectDemo />} />

            <Route path="" element={<Project />}>
              <Route path={routesManager.project.overview.shortPattern} element={<ProjectDashboard />} />
              <Route path={routesManager.project.workflow.shortPattern} element={<Workflow />} />
              <Route path={routesManager.project.canvas.shortPattern} element={<CanvasTab />} />
              <Route path={routesManager.project.members.shortPattern} element={<MembersTab />} />
              <Route path={routesManager.project.files.shortPattern} element={<Files />} />
              <Route path={routesManager.project.tasks.shortPattern} element={<Tasks />} />
              <Route path={routesManager.project.timeline.shortPattern} element={<Timeline />} />
              <Route path={routesManager.project.pinboard.shortPattern} element={<Pinboard />} />
            </Route>
          </Route>

          <Route path={routesManager.template.root.pattern} element={<TemplatePage />}>
            <Route element={<Template />}>
              <Route index element={<Navigate to={routesManager.project.canvas.shortPattern} replace />} />

              <Route path={routesManager.project.canvas.shortPattern} element={<CanvasTemplate />} />

              <Route path={routesManager.project.tasks.shortPattern} element={<TemplateTasks />} />
            </Route>
          </Route>

          <Route
            path={routesManager.templates.root.pattern}
            element={
              <PermittedAccess
                permission={[
                  AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                  AppPermissions.ORCHESTRATION_PROJECTS_CREATE,
                  AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW,
                ]}
              />
            }
          >
            <Route index element={<Templates />} />
          </Route>

          <Route path={routesManager.tasks.root.pattern} element={<TasksDashboardPage />} />

          <Route
            path={`${routesManager.settings.root.pattern}/*`}
            element={
              <PermittedAccess
                permission={[
                  AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                  AppPermissions.ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE,
                ]}
              />
            }
          >
            <Route path="*" element={<Settings />} />
          </Route>

          <Route path={routesManager['404'].root.pattern} element={<NotFound />} />

          <Route path="*" element={<Navigate replace to={routesManager.projects.root.pattern} />} />
        </Routes>
      </NiceModalProvider>
    </BrowserRouter>
  )
}
