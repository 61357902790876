import { useTranslation } from 'react-i18next'

import { PINBOARD_URL } from 'constants/pinboard-config'
import styles from 'pages/project/components/pinboard/Pinboard.module.scss'

export const Pinboard = () => {
  const { t } = useTranslation()

  return <iframe className={styles.iframeRoot} src={PINBOARD_URL} title={t('pinboard.title')} />
}
