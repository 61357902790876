import { useCallback } from 'react'

import { useFetchProjectListApi } from 'api/projects/queryFetchers/useFetchProjectListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useHaveAnyAttributes } from 'pages/dashboard/utils/attributes'
import { Project, ProjectFilter, ProjectOwnership } from 'types/projects/projects'

interface Props {
  filter: ProjectFilter
}

export const useProjectListLoader = ({
  filter: { search, ownership, workspace, type, status, tenant, attributes },
}: Props) => {
  const searchStable = useLatestDistinct(search)
  const ownershipStable = useLatestDistinct(ownership)
  const typeStable = useLatestDistinct(type)
  const workspaceStable = useLatestDistinct(workspace)
  const statusStable = useLatestDistinct(status)
  const tenantStable = useLatestDistinct(tenant)

  const handleFetchProjectsList = useFetchProjectListApi()

  const haveAnyAttributes = useHaveAnyAttributes(attributes)

  const loader: TableInfiniteLoader<Project> = useCallback(
    async ({ endRow, startRow }) => {
      const itemsPerPage = endRow - startRow

      /*
       * @TODO: this should be removed, because now BE calculate empty array like
       *   empty filter and return all items, so when `workspace: []` they shouldn't return anything.
       *   JIRA: WPPLONOP-10970
       * */
      if (Array.isArray(workspaceStable) && !workspaceStable.length) {
        return {
          data: [],
          totalRowsCount: 0,
        }
      }

      const {
        data: { data, paginator },
      } = await handleFetchProjectsList({
        search: searchStable,
        ownership: ownershipStable === ProjectOwnership.ALL ? undefined : ownershipStable,
        type: typeStable,
        workspace: workspaceStable,
        status: statusStable,
        tenant: tenantStable,
        attributes: Object.keys(haveAnyAttributes).length ? haveAnyAttributes : undefined,
        itemsPerPage,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: paginator.totalItems,
      }
    },
    [
      workspaceStable,
      handleFetchProjectsList,
      searchStable,
      ownershipStable,
      typeStable,
      statusStable,
      tenantStable,
      haveAnyAttributes,
    ],
  )

  return { loader }
}
