import { useMemo } from 'react'

import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { LinearEditableActivity } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/LinearEditableActivity'
import { LinearPreviewActivity } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/LinearPreviewActivity'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem } from 'types/projects/workflow'

interface Props {
  activity: ActivityItem
  showAction?: boolean
  isEditable: boolean
  isDisabled: boolean
  index?: number
  changeAssignee?: (newAssignee: ResponsibleUser) => Promise<void>
  changeStatus?: (status: string) => void
  changeDates?: (dates: Date[]) => void
  editActivity?: () => void
  deleteActivity?: () => void
  changeHidden?: (memberIds: string[]) => void
  phaseItemId?: string
  variant?: 'primary' | 'secondary'
  phaseId?: string
  preview?: boolean
  isInactive?: boolean
  isIAssignToThisPhase?: boolean
  isDraggingDisabled?: boolean
  isWrikeConnected?: boolean
  projectId: string
  isTemplate?: boolean
}

export const LinearActivity = ({
  activity,
  showAction,
  isEditable,
  isDisabled,
  index,
  variant,
  preview,
  phaseId,
  phaseItemId,
  isInactive,
  isIAssignToThisPhase,
  isDraggingDisabled,
  isWrikeConnected,
  projectId,
  isTemplate,
  changeHidden,
  ...rest
}: Props) => {
  const { changeDates, editActivity, changeAssignee, changeStatus } = rest
  const { externalLinks } = activity

  const sortedExternalLinksByNewest = useMemo(() => {
    return externalLinks?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }, [externalLinks])

  return preview ? (
    <LinearPreviewActivity
      activityItem={activity}
      isDisabled={isDisabled}
      sortedExternalLinks={sortedExternalLinksByNewest}
      phaseId={phaseId!}
    />
  ) : (
    <LinearEditableActivity
      activityItem={activity}
      showAction={showAction!}
      isIAssignToThisPhase={isIAssignToThisPhase}
      isEditable={isEditable}
      isDisabled={isDisabled}
      isInactive={!!isInactive}
      index={index!}
      variant={variant}
      phaseId={phaseId!}
      phaseItemId={phaseItemId!}
      changeAssignee={changeAssignee!}
      changeDates={changeDates!}
      changeStatus={changeStatus!}
      changeHidden={changeHidden!}
      editActivity={editActivity}
      sortedExternalLinks={sortedExternalLinksByNewest}
      isDraggingDisabled={isDraggingDisabled}
      isWrikeConnected={isWrikeConnected}
      projectId={projectId}
      processType={ProcessType.LINEAR}
      isTemplate={isTemplate}
    />
  )
}
