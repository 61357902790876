import { useCallback } from 'react'

import { useFetchTasksListApi } from 'api/tasks/queries/useFetchTasksListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { useHaveAnyAttributes } from 'pages/dashboard/utils/attributes'
import { allStatuses } from 'pages/project/components/tasks/utils'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { TasksFilter, TaskStatus } from 'types/projects/tasks'

interface Props {
  filters: TasksFilter
}

export const useTasksListLoader = ({
  filters: {
    search,
    dueDateRanges,
    selectedProjects,
    workspace,
    tenant,
    selectedStatuses,
    attributes,
    includeEmptyWorkspace,
  },
}: Props) => {
  const searchStable = useLatestDistinct(search)
  const dueDateRangesStable = useLatestDistinct(dueDateRanges)
  const selectedProjectsStable = useLatestDistinct(selectedProjects)
  const workspacesStable = useLatestDistinct(workspace)
  const tenantStable = useLatestDistinct(tenant)

  const statuses = selectedStatuses?.length ? selectedStatuses : allStatuses

  const handleFetchTasksList = useFetchTasksListApi()

  const haveAnyAttributes = useHaveAnyAttributes(attributes)

  const loader: TableInfiniteLoader<TaskDashboard> = useCallback(
    async ({ endRow, startRow }) => {
      const itemsPerPage = endRow - startRow

      const {
        data: { data, paginator },
      } = await handleFetchTasksList({
        text: searchStable,
        statuses: statuses as TaskStatus[],
        dueDateRanges: dueDateRangesStable,
        projectIds: selectedProjectsStable,
        workspace: workspacesStable,
        tenant: tenantStable,
        attributes: Object.keys(haveAnyAttributes).length ? haveAnyAttributes : undefined,
        itemsPerPage,
        includeEmptyWorkspace,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: paginator.totalItems,
      }
    },
    [
      workspacesStable,
      handleFetchTasksList,
      searchStable,
      statuses,
      dueDateRangesStable,
      selectedProjectsStable,
      tenantStable,
      haveAnyAttributes,
      includeEmptyWorkspace,
    ],
  )

  return { loader }
}
