import { WppIconApp, WppIconFolder } from '@platform-ui-kit/components-library-react'

import { useProjectTaskApi } from 'api/projects/queries/useProjectTaskApi'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useTemplate } from 'hooks/useTemplate'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { TemplateTaskDetails } from 'pages/template/tasks/details/TemplateTaskDetails'
import { AppPermissions } from 'types/permissions/permissions'
import { TaskTargetType } from 'types/projects/tasks'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export const taskTargetTypeMap = {
  [TaskTargetType.ACTIVITY]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.activity',
  },
  [TaskTargetType.APPLICATION]: {
    icon: <WppIconApp />,
    name: 'project.tasks.item_type.application',
  },
  [TaskTargetType.PHASE]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.phase',
  },
}

interface Props extends NiceModalWrappedProps {
  taskId: string
}

const TemplateTaskDetailsModal = ({ taskId, onClose, onCloseComplete, isOpen }: Props) => {
  const { data: task, isLoading: isTaskLoading, isError, error } = useProjectTaskApi({ params: { id: taskId } })

  const { isPermitted } = useIsPermitted()
  const { isOwner } = useTemplate()

  const isOwnerOrGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || isOwner

  return (
    <TemplateTaskDetails
      id="task-modal-view"
      task={task}
      canEdit={isOwnerOrGlobalManage}
      isLoading={isTaskLoading}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      isOpen={isOpen}
      isError={isError}
      error={error}
      showProjectName
    />
  )
}

export const { showModal: showTemplateTaskDetailsModal, useModal: useShowTemplateTaskDetailsModal } =
  createProjectModal<Props>(TemplateTaskDetailsModal, 'task-template-details-modal')
