import { projectApi } from 'api'
import { ProjectAttribute } from 'types/projects/attributes'

export type UpdateProjectAttributesResponse = Omit<ProjectAttribute, 'id' | 'tenantId' | 'type' | 'classification'>

interface Props {
  id: string
  data: UpdateProjectAttributesResponse
}

export const updateAttributesApi = ({ id, data }: Props) => projectApi.patch(`/project/attributes/${id}`, data)
