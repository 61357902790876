import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppButton,
  WppIconChevron,
  WppTab,
  WppTabs,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyCustomNodeType, HierarchyNode } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { UpdateProjectAttribute, UpdateProjectDTO } from 'api/projects/fetchers/updateProjectApi'
import { useUpdateProjectApi } from 'api/projects/mutation/useUpdateProjectApi'
import { useFetchProjectAttributesApi } from 'api/projects/queries/useFetchAttributesByProjectApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import useEventSource from 'hooks/useEventSource'
import { useHierarchy } from 'hooks/useHierarchy'
import { useProjectAnalytics } from 'hooks/useProjectAnalytics'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import { useWrike } from 'hooks/useWrike'
import { showAppCtxWarningModal } from 'pages/components/projectModal/components/appCtxWarningModal/components/AppCtxWarningModal'
import { WrikeConnect } from 'pages/components/projectModal/components/integrations/wrike/WrikeConnect'
import { WrikeIntegrationsMessage } from 'pages/components/projectModal/components/integrations/wrike/WrikeIntegrationsMessage'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import styles from 'pages/components/projectModal/EditProjectModal.module.scss'
import { getInitialProjectFormDate, normalizeProjectFormDate } from 'pages/components/projectModal/utils'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttributeClass } from 'types/projects/attributes'
import { ContextHierarchy, Project } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { CustomError, is409Error } from 'utils/error'

interface Props extends NiceModalWrappedProps {
  project: Project
  showIntegration?: boolean
  showBackButton?: boolean
}

enum ProjectTab {
  Details = 'details',
  Integrations = 'integrations',
}

const EditProjectModal = ({ isOpen, onClose, onCloseComplete, project, showBackButton, showIntegration }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [currentTab, setCurrentTab] = useState(showIntegration ? ProjectTab.Integrations : ProjectTab.Details)
  const eventSource = useEventSource()
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()

  const { trackHierarchyChange, trackProcessTypeChange, trackWrikeAction, trackProjectEdit, trackProjectEditCancel } =
    useProjectAnalytics({
      project,
      eventSource,
      calculateTimeDifferenceInSeconds,
    })

  const {
    osContext: { navigationTree },
  } = useOs()
  const [wrikeError, setWrikeError] = useState(null)

  const { data: attributes = [] } = useFetchProjectAttributesApi({
    params: { projectId: project.id! },
    enabled: !!project.id,
  })

  const { hierarchyOrder, getWorkspaceTree } = useHierarchy()
  const { isWrikeAvailable, integrationAvailable } = useWrike()

  const { mutateAsync: updateProject } = useUpdateProjectApi()

  const { name, type, description, contextWorkspace, processType, startDate, endDate, contextHierarchy, wrike } =
    project

  const workspaceData = getWorkspaceTree(contextWorkspace)

  const workspaceFormFields = hierarchyOrder.reduce((previousValue, currentValue) => {
    const curr = workspaceData.find(
      el =>
        currentValue.toLowerCase() ===
        (el.type === HierarchyCustomNodeType ? el.customTypeName : el.type)!.toLowerCase(),
    )

    return {
      ...previousValue,
      [currentValue.toLowerCase()]: curr?.azId || '',
    }
  }, {})

  const isWorkspaceValid = contextWorkspace === null || (!!contextWorkspace && !!workspaceData.length)

  // system attributes for form
  const systemAttributes = useMemo(
    () => attributes?.filter(attribute => attribute.classification === ProjectAttributeClass.SYSTEM),
    [attributes],
  )

  const customAttributes = useMemo(
    () =>
      attributes
        .filter(attribute => attribute.classification === ProjectAttributeClass.CUSTOM && attribute.enabled)
        .sort((a, b) => a.orderNumber! - b.orderNumber!),
    [attributes],
  )

  const defaultValues = useMemo(
    () => ({
      name,
      type,
      description: description ?? '',
      processType,
      dueDate: getInitialProjectFormDate({ startDate, endDate }),
      wrikeProjectId: wrike?.wrikeProjectId ?? '',
      disconnectWrike: !wrike?.isConnected,
      wrikeConnectionToggle: !!wrike?.isConnected,
      hierarchy: workspaceFormFields,
      customAttributes:
        customAttributes?.reduce(
          (acc, { contractName, defaultValue, value }) => ({
            ...acc,
            [contractName]: value || defaultValue,
          }),
          {},
        ) || {},
    }),
    [
      name,
      type,
      description,
      processType,
      workspaceFormFields,
      startDate,
      endDate,
      wrike?.wrikeProjectId,
      wrike?.isConnected,
      customAttributes,
    ],
  )

  const form = useForm({
    defaultValues,
    validationSchema: modalValidationScheme(hierarchyOrder, [...systemAttributes, ...customAttributes]),
  })
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
    trigger,
  } = form

  const onSubmit = handleSubmit(
    async () => {
      if (!isValid) {
        await trigger()
        return
      }

      await handleSave()
    },
    errors => console.error('Form is not valid', errors),
  )

  const handleSave = useCallback(
    async (skipAffectedApps = false) => {
      setWrikeError(null)

      const { wrikeProjectId, disconnectWrike, hierarchy, customAttributes, ...systemAttributes } = getValues()

      const selectedWorkspace = hierarchyOrder.map(
        order => hierarchy[order as keyof typeof hierarchy] || '',
      ) as string[]
      const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

      const projectAttributes = (attributes || [])
        .map(attr => {
          if (attr.contractName === 'startDate_endDate') {
            const value = !!systemAttributes.dueDate.length
              ? Object.values(normalizeProjectFormDate(systemAttributes.dueDate)).join('_')
              : null
            return {
              id: attr.id,
              value,
            }
          }

          // CONTEXT_WORKSPACE : "context" can be changed to any other key, so this comment is needed to track the key across project
          if (attr.contractName === 'contextWorkspace') {
            return {
              id: attr.id,
              value: contextWorkspace,
            }
          }

          const formAttributes = { ...systemAttributes, ...customAttributes }
          if (attr.contractName in formAttributes) {
            return {
              id: attr.id,
              value: formAttributes[attr.contractName as keyof typeof formAttributes] || '',
            }
          }

          return null
        })
        .filter(Boolean)

      const newContextHierarchy: ContextHierarchy[] = selectedWorkspace.map((id, index) => {
        const node = navigationTree.mapping[id] as HierarchyNode
        return {
          title: hierarchyOrder[index],
          name: node?.name || null,
          value: node?.azId || null,
          alias: node?.alias || null,
          originalName: node?.originalName || node?.name || null,
        } as ContextHierarchy
      })

      try {
        let projectBody: UpdateProjectDTO = {
          projectAttributes: projectAttributes as UpdateProjectAttribute[],
          contextHierarchy: newContextHierarchy,
          skipAffectedApps,
          wrikeProjectId,
          disconnectWrike,
        }

        if (wrikeProjectId) {
          projectBody = { ...projectBody, wrikeProjectId, disconnectWrike }
        }

        await updateProject({
          id: project.id,
          project: projectBody,
        })

        // Check if hierarchy or process type has changed
        trackHierarchyChange(newContextHierarchy)
        trackProcessTypeChange(systemAttributes.processType)

        await Promise.all([
          queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECT_INTEGRATION]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES]),
        ])

        tableActions.reload([TableKey.PROJECT_LIST])

        if (wrikeProjectId) {
          await queryClient.invalidateQueries([ApiQueryKeys.MEMBERS])

          trackWrikeAction(disconnectWrike)
        }

        trackProjectEdit({ processType: systemAttributes.processType, contextHierarchy: newContextHierarchy })

        onClose()
      } catch (e: any) {
        console.error(e)

        const errorDetails = e?.response?.data?.detail
        if (errorDetails?.code?.includes('WRIKE_')) {
          setWrikeError(e)
          return
        }

        if (errorDetails?.code === CustomError.AFFECTED_APPS_FOUND) {
          showAppCtxWarningModal({ warnings: errorDetails.data, onForceSave: () => handleSave(true) })
          return
        }

        const message = is409Error((e as AxiosError)?.response?.status)
          ? t('modals.create_project.toast_error_duplicate')
          : t('modals.create_project.toast_error_common')
        showToast({
          type: 'error',
          message,
        })
      }
    },
    [
      getValues,
      hierarchyOrder,
      attributes,
      navigationTree.mapping,
      updateProject,
      project.id,
      trackHierarchyChange,
      trackProcessTypeChange,
      trackProjectEdit,
      onClose,
      trackWrikeAction,
      t,
      showToast,
    ],
  )

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as ProjectTab)
  }

  const availableTabs = useMemo(() => {
    const tabs: ProjectTab[] = [ProjectTab.Details]
    if (integrationAvailable) {
      tabs.push(ProjectTab.Integrations)
    }

    return tabs
  }, [integrationAvailable])

  const handleCancelEdit = () => {
    onClose()

    trackProjectEditCancel()
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={handleCancelEdit}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading">
            {showBackButton && (
              <WppActionButton className={styles.backButton} onClick={handleCancelEdit}>
                <WppIconChevron direction="left" />
              </WppActionButton>
            )}

            {t('modals.edit_project.title')}
          </WppTypography>

          <div slot="body">
            {availableTabs.length > 1 && (
              <WppTabs
                value={currentTab}
                className="wpp-spacing-24-bottom"
                onWppChange={handleTabChange}
                data-testid="project-tabs"
              >
                <WppTab value={ProjectTab.Details} data-testid="project-details-tab">
                  {t('modals.edit_project.tab.details')}
                </WppTab>

                {availableTabs.includes(ProjectTab.Integrations) && (
                  <WppTab
                    value={ProjectTab.Integrations}
                    style={{ whiteSpace: 'nowrap' }}
                    data-testid="project-integration-tab"
                  >
                    {t('modals.edit_project.tab.integrations')}
                  </WppTab>
                )}
              </WppTabs>
            )}

            {currentTab === ProjectTab.Details && (
              <ProjectForm
                showWarning={!isWorkspaceValid}
                contextHierarchy={isWorkspaceValid ? [] : contextHierarchy}
                showProcessType={true}
                systemAttributes={systemAttributes}
                customAttributes={customAttributes}
              />
            )}

            {currentTab === ProjectTab.Integrations && (
              <>
                <Flex direction="column" className="wpp-spacing-24-bottom">
                  {isWrikeAvailable && <WrikeConnect wrike={wrike} wrikeError={wrikeError} />}
                </Flex>
                <WrikeIntegrationsMessage />
              </>
            )}
          </div>

          <Flex slot="actions" justify="end" gap={12}>
            <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
              {t('modals.create_project.btn_cancel')}
            </WppButton>
            <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
              {t('modals.edit_project.btn_save')}
            </WppButton>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showProjectEditModal } = createNiceModal<Props>(EditProjectModal, 'edit-project-modal')
