import { WppAvatar } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentPropsWithoutRef, useMemo } from 'react'

import { getAvatarColor } from 'components/common/avatar/utils'

type Props = ComponentPropsWithoutRef<typeof WppAvatar>

export const Avatar = forwardRef<HTMLWppAvatarElement, Props>(function Avatar({ name, ...rest }, ref) {
  const color = useMemo(() => getAvatarColor({ name }), [name])

  return <WppAvatar ref={ref} name={name} color={color} {...rest} />
})
