import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showTemplateTaskDetailsModal,
  useShowTemplateTaskDetailsModal,
} from 'pages/template/tasks/details/TemplateTaskDetailsModal'
import { DetailsModalType } from 'types/common/utils'

export const useOpenTemplateTaskPreviewModal = () => {
  const { visible, hide } = useShowTemplateTaskDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, id } = Object.fromEntries(params.entries()) as { view: string; id: string }

    if (view && view === DetailsModalType.TEMPLATE_TASK_DETAILS_PREVIEW) {
      showTemplateTaskDetailsModal({ taskId: id, onClose: setParams })
    } else visible && hide()
  }, [hide, params, setParams, visible])
}
