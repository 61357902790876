import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { MoveActivityProps } from 'api/canvas/fetchers/moveActivityApi'
import { useMoveActivityApi } from 'api/canvas/mutation/useMoveActivityApi'
import { CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import { LinearData } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'

export function useMoveLinearActivity({ projectId }: { projectId: string }) {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const projectContext = useProject()

  const { trackItemMove } = useWorkflowEditAnalytics()

  const { mutateAsync: handleMoveActivity, isLoading } = useMoveActivityApi({
    onMutate: async patchData => {
      const projectLinearQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectLinearQueryKey)

      const previousLinear = queryClient.getQueryData<{ data: LinearData }>(projectLinearQueryKey)

      if (previousLinear) {
        const newData = {
          ...previousLinear,
          data: {
            ...previousLinear.data,
            ...patchData.optimisticData,
          },
        }
        queryClient.setQueryData(projectLinearQueryKey, newData)
      }

      return { previousLinear }
    },
    onError: (_error, _, context) => {
      if (context?.previousLinear) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], context.previousLinear)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
    },
  })

  const moveActivity = useCallback(
    async (params: MoveActivityProps) => {
      try {
        await handleMoveActivity(params)
        if (projectContext) {
          trackItemMove(CanvasItemType.ACTIVITY)
        }
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
        console.error(error)
      }
    },
    [handleMoveActivity, projectContext, showToast, t, trackItemMove],
  )

  return { moveActivity, isLoading }
}
