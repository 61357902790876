import { MayBeNull } from '@wpp-open/core'

export enum ProjectAttributeClass {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
}

export enum ProjectAttributeType {
  TEXT_AREA = 'TEXT_AREA',
  TEXT_INPUT = 'TEXT_INPUT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE_RANGE = 'DATE_RANGE',
  HIERARCHY = 'HIERARCHY',
}

export interface ProjectAttribute {
  id: string
  tenantId: string
  name: string
  contractName: string
  type: ProjectAttributeType
  classification: ProjectAttributeClass
  enabled: boolean
  required: boolean
  helperText: string
  iconText: string
  externalKey: string
  valueOptions: MayBeNull<string[]>
  defaultValue: MayBeNull<string | []>
  orderNumber?: number
}
