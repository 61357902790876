import { WppTypography, WppEmptyNothingFound } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/projectDashboard/coponents/tasksStatusBoard/TasksStatusBoard.module.scss'

export const EmptyPieChart = () => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" align="center" gap={24} className={styles.empty} data-testid="no-tasks">
      <WppEmptyNothingFound />
      <Flex gap={8} direction="column" align="center">
        <WppTypography type="l-strong" data-testid="no-tasks-title">
          {t('project.tasks.no_tasks')}
        </WppTypography>
        <WppTypography type="s-body" className={styles.subTitle} data-testid="no-tasks-description">
          {t('project.tasks.have_not_assigned')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
