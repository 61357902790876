import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { useAddActivityLinksApi } from 'api/canvas/mutation/useAddActivityLinksApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { CanvasItemType } from 'constants/analytics'
import { useForm } from 'hooks/form/useForm'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import { ActivityExternalLinksControl } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/ActivityExternalLinksControl'
import { externalLink, invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface BaseProps extends NiceModalWrappedProps {
  projectId: string
  activityId: string
  existingCount: number
}

const defaultValues = {
  externalLinks: [],
}

const validationSchema = zod
  .object({
    externalLinks: zod.array(externalLink),
  })
  .passthrough()

const AddLinksModal = ({ activityId, existingCount, isOpen, projectId, onClose, onCloseComplete }: BaseProps) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const projectContext = useProject()
  const { trackItemAdd } = useWorkflowEditAnalytics()

  const form = useForm({ defaultValues, validationSchema })
  const { mutateAsync: addActivityLinks } = useAddActivityLinksApi()

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const { externalLinks } = values

      await addActivityLinks({ activityId, externalLinks })
      invalidateCanvas()
      showToast({
        type: 'success',
        message: t(
          externalLinks.length > 1
            ? 'project.links_modal.toast_success_added_links'
            : 'project.links_modal.toast_success_added_link',
          { count: externalLinks.length },
        )!,
      })
      if (projectContext) {
        trackItemAdd({
          type: CanvasItemType.LINK,
          numberOfApps: externalLinks.length,
        })
      }
      onClose()
    } catch (e) {
      console.error(e)
      showToast({ type: 'error', message: t('project.links_modal.toast_error_add_message')! })
    }
  })

  const { externalLinks } = watch()
  const linksCount = externalLinks.length

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.links_modal.add_title')}
        </WppTypography>

        <Flex direction="column" slot="body" gap={24}>
          <ActivityExternalLinksControl existingCount={existingCount} projectId={projectId} />
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton size="m" variant="secondary" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton size="m" type="submit" loading={isSubmitting} disabled={!linksCount} variant="primary">
            {t('project.links_modal.btn_add', { count: linksCount })}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAddLinksModal } = createProjectModal<BaseProps>(AddLinksModal, 'add-links-modal')
