import {
  WppActionButton,
  WppIconChevron,
  WppIconRemoveCircle,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { GroupAssignment } from 'pages/components/projectModal/components/membersGroups/utils'
import styles from 'pages/components/projectModal/components/membersRoles/MembersRoles.module.scss'
import { CreateProjectFormType } from 'pages/components/projectModal/CreateProjectModal'
import { roleOption } from 'pages/project/components/members/components/utils'
import { ProjectRole } from 'types/permissions/permissions'
import { capitalizeFirstLetter } from 'utils/common'

export const MembersRoles = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext<CreateProjectFormType>()
  const groupAssignments = watch('groupAssignments').filter(Boolean) as GroupAssignment[]

  const handleChangeRole = (assignment: GroupAssignment, role: ProjectRole) => {
    setValue(`groupAssignments.${assignment.index}`, {
      ...assignment,
      role,
    })
  }
  const handleRemove = ({ index }: GroupAssignment) => {
    setValue(`groupAssignments.${index}`, null)
  }

  return (
    <>
      <WppTypography type="l-strong" className={styles.header}>
        {t('modals.create_project.members_groups.assignment_roles')}
      </WppTypography>

      <Flex direction="column" gap={8}>
        {groupAssignments.map(assignment => (
          <Flex key={assignment.email} className={styles.row}>
            <Flex className={styles.assignmentCard} gap={8} align="center">
              <Avatar
                size="s"
                name={assignment.username}
                src={assignment.avatarUrl ?? ''}
                className={styles.noShrink}
              />

              <Flex direction="column" className={styles.ellipsis}>
                <Truncate type="s-midi" lines={1}>
                  {assignment.username}
                </Truncate>
                <Truncate type="s-body" lines={1} className={styles.email}>
                  {assignment.email}
                </Truncate>
              </Flex>
            </Flex>

            <div className={styles.noShrink}>
              <WppMenuContext>
                <WppActionButton slot="trigger-element" variant="secondary">
                  {capitalizeFirstLetter(assignment.role?.toLowerCase())}
                  <WppIconChevron direction="down" color="var(--wpp-grey-color-800)" className="wpp-spacing-4-left" />
                </WppActionButton>

                <WppMenuGroup withDivider>
                  {roleOption.map(role => (
                    <WppListItem key={role.value} onWppChangeListItem={() => handleChangeRole(assignment, role.value)}>
                      <WppTypography slot="label" type="s-body">
                        {role.label}
                      </WppTypography>
                    </WppListItem>
                  ))}
                </WppMenuGroup>

                <WppMenuGroup>
                  <WppListItem onWppChangeListItem={() => handleRemove(assignment)}>
                    <WppIconRemoveCircle slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('project.canvas.remove')}
                    </WppTypography>
                  </WppListItem>
                </WppMenuGroup>
              </WppMenuContext>
            </div>
          </Flex>
        ))}
      </Flex>
    </>
  )
}
