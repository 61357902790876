import { useMemo } from 'react'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions } from 'types/permissions/permissions'
import { projectTabs } from 'utils/project'

export function useProjectTabs() {
  const { isPermitted } = useIsPermitted()
  const { hasRole, isResponsible } = useHasProjectRole()

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  return useMemo(() => {
    return isGlobalManage
      ? projectTabs
      : projectTabs
          .filter(({ permissions }) => {
            return !permissions || isPermitted(permissions)
          })
          .filter(({ roles, forResponsible }) => {
            if (forResponsible && isResponsible()) return true
            return !roles || hasRole(roles)
          })
  }, [hasRole, isGlobalManage, isPermitted, isResponsible])
}
