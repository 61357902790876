import {
  WppActionButton,
  WppIconEdit,
  WppIconMore,
  WppIconReject,
  WppIconSuccess,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteAttributeByIdApi } from 'api/projects/mutation/useDeleteAttributeByIdApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, isLoadingMoreRow } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { showAttributeModal } from 'pages/settings/attributes/components/addAttributeModal/AttributeModal'
import styles from 'pages/settings/attributes/components/attributesTable/AttributesTable.module.scss'
import { typeLabels } from 'pages/settings/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttribute } from 'types/projects/attributes'

const LoadingCellRenderer = () => <WppSkeleton height="50%" />

function getCellRenderer(
  CellRenderer: ColDef<ProjectAttribute>['cellRenderer'],
): NonNullable<ColDef<ProjectAttribute>['cellRenderer']> {
  return function (params) {
    const { value, valueFormatted, data, node } = params

    return isLoadingMoreRow<ProjectAttribute>({ data, node }) ? (
      <LoadingCellRenderer />
    ) : (
      <>{CellRenderer ? <CellRenderer {...params} /> : valueFormatted || value}</>
    )
  }
}

export const useAttributesTableColumns = (withActions = false) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteAttribute } = useDeleteAttributeByIdApi()

  return useMemo<ColDef<ProjectAttribute>[]>(
    () => [
      {
        colId: 'name',
        field: 'name',
        sortable: true,
        flex: 1,
        minWidth: 150,
        rowDrag: withActions,

        headerName: t('project.settings.attributes_table.name'),
        cellRenderer: getCellRenderer(({ data }) => (
          <Flex className={styles.nameWrapper}>
            <WppTypography type="s-body" className={styles.ellipsis}>
              {data!.name}
            </WppTypography>
          </Flex>
        )),
      },
      {
        colId: 'type',
        field: 'type',
        sortable: true,
        flex: 1,
        minWidth: 150,

        headerName: t('project.settings.attributes_table.type'),
        cellRenderer: getCellRenderer(({ data }) => (
          <WppTypography type="s-body">{t(typeLabels[data?.type!] ?? data?.type)}</WppTypography>
        )),
      },
      {
        colId: 'enabled',
        field: 'enabled',
        sortable: true,
        flex: 1,
        minWidth: 150,

        headerName: t('project.settings.attributes_table.enabled'),
        cellRenderer: getCellRenderer(({ data }) => (
          <Flex gap={4} align="center">
            {data?.enabled ? (
              <>
                <WppIconSuccess className={styles.enabledIcon} />
                <WppTypography type="s-body">{t('project.settings.attributes_table.yes')}</WppTypography>
              </>
            ) : (
              <>
                <WppIconReject slot="icon-start" className={styles.disabledIcon} />
                <WppTypography type="s-body">{t('project.settings.attributes_table.no')}</WppTypography>
              </>
            )}
          </Flex>
        )),
      },
      {
        colId: 'required',
        field: 'required',
        sortable: true,
        flex: 1,
        minWidth: 150,

        headerName: t('project.settings.attributes_table.required'),
        cellRenderer: getCellRenderer(({ data }) => (
          <WppTypography type="s-body">
            {data?.required
              ? t('project.settings.attributes_table.mandatory')
              : t('project.settings.attributes_table.optional')}
          </WppTypography>
        )),
      },
      {
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return withActions ? (
            <WppMenuContext style={{ lineHeight: '100%' }}>
              <WppActionButton slot="trigger-element" variant="secondary">
                <WppIconMore slot="icon-start" direction="horizontal" color="var(--wpp-primary-color-500)" />
              </WppActionButton>
              <WppMenuGroup>
                <WppListItem
                  data-testid="edit-attribute-action"
                  onWppChangeListItem={() => {
                    showAttributeModal({ data })
                  }}
                >
                  <WppIconEdit slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('common.btn_edit')}
                  </WppTypography>
                </WppListItem>
                <WppListItem
                  data-testid="delete-attribute-action"
                  onWppChangeListItem={() => {
                    showDeleteModal({
                      title: t('project.settings.delete_attribute.delete_title'),
                      subTitle: t('project.settings.delete_attribute.delete_sub_title', { attribute: data?.name! }),
                      onDelete: async () => {
                        await deleteAttribute({ attributeId: data?.id! })
                        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_ATTRIBUTES_BLUEPRINT])
                      },
                    })
                  }}
                >
                  <WppIconTrash slot="left" />
                  <WppTypography slot="label" type="s-body">
                    {t('common.btn_delete')}
                  </WppTypography>
                </WppListItem>
              </WppMenuGroup>
            </WppMenuContext>
          ) : null
        },
        width: 64,
        minWidth: 64,
        resizable: false,
        pinned: 'right',
      },
    ],
    [deleteAttribute, t, withActions],
  )
}
