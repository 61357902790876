import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppIconEdit,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import styles from 'pages/project/components/projectDashboard/ProjectDashboard.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { capitalizeFirstLetter } from 'utils/common'
import { formatDate } from 'utils/dateFormat'

const findType = (nodeType: HierarchyLevelType, nodes: NavigationTreeNode[]) => {
  return nodes.find(({ type, customTypeName }) => {
    return (
      nodeType.toLowerCase() ===
      (type.toLowerCase() === HierarchyCustomNodeType.toLowerCase()
        ? customTypeName?.toLowerCase()
        : type.toLowerCase())
    )
  })
}

export const ProjectInfo = () => {
  const { t } = useTranslation()
  const { project, isInactive, attributes } = useProject()
  const { contextWorkspace, contextHierarchy, description, type, processType, createdAt, startDate, endDate, id } =
    project
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()
  const { getWorkspaceTree } = useHierarchy()
  const { hierarchyOrder } = useHierarchy()

  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
  const workspaceData = getWorkspaceTree(contextWorkspace)
  const isWorkspaceValid = contextWorkspace === null || (!!contextWorkspace && !!workspaceData.length)

  const customAttributes = attributes.filter(attribute => attribute.classification === 'CUSTOM' && attribute.enabled)

  return (
    <WppCard className={styles.detailsCard} size="l" data-testid="project-details-card">
      <WppTypography slot="header" type="l-strong">
        {t('overview.project_details')}
      </WppTypography>
      <Flex direction="column" gap={12}>
        <Flex gap={12} data-testid="project-type-details">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('overview.project_type')}
          </WppTypography>
          <WppTypography type="s-body" className={styles.secondColumn}>
            {capitalizeFirstLetter(type.toLowerCase())}
          </WppTypography>
        </Flex>
        <Flex gap={12} data-testid="project-process-type">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('overview.process')}
          </WppTypography>
          <WppTypography type="s-body" className={styles.secondColumn}>
            {t(`overview.process_type_${processType}`)}
          </WppTypography>
        </Flex>
        {!isWorkspaceValid
          ? contextHierarchy.map(hierarchy => (
              <Flex key={hierarchy.value} gap={12} data-testid={`project-${hierarchy.title}`}>
                <WppTypography
                  className={clsx(styles.firstColumn, { [styles.disabled]: !isWorkspaceValid })}
                  type="s-strong"
                >
                  {t(`overview.${hierarchy}`, {
                    defaultValue: capitalizeFirstLetter(hierarchy.title || ''),
                  })}
                </WppTypography>
                <WppTooltip text={t('project.about.incorrect_workspace')}>
                  <WppTypography
                    type="s-body"
                    className={clsx(styles.secondColumn, { [styles.disabled]: !isWorkspaceValid })}
                  >
                    {hierarchy.name || '-'}
                  </WppTypography>
                </WppTooltip>
              </Flex>
            ))
          : hierarchyOrder.map(hierarchy => (
              <Flex key={hierarchy} gap={12} data-testid={`project-${hierarchy}`}>
                <WppTypography className={styles.firstColumn} type="s-strong">
                  {t(`overview.${hierarchy}`, {
                    defaultValue: capitalizeFirstLetter(hierarchy),
                  })}
                </WppTypography>
                <WppTypography type="s-body" className={styles.secondColumn}>
                  {findType(hierarchy.toUpperCase() as HierarchyLevelType, workspaceData)?.name || '-'}
                </WppTypography>
              </Flex>
            ))}
        <Flex gap={12} data-testid="project-start-date">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('common.start_date')}
          </WppTypography>
          <WppTypography type="s-body" className={styles.secondColumn}>
            {startDate
              ? formatDate({
                  dateString: startDate,
                  dateLocale,
                })
              : '-'}
          </WppTypography>
        </Flex>
        <Flex gap={12} data-testid="project-end-date">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('common.end_date')}
          </WppTypography>
          <WppTypography type="s-body" className={styles.secondColumn}>
            {endDate
              ? formatDate({
                  dateString: endDate,
                  dateLocale,
                })
              : '-'}
          </WppTypography>
        </Flex>
        {!!customAttributes.length && <WppDivider />}
        {customAttributes.map(attribute => (
          <Flex key={attribute.id} gap={12}>
            <Truncate lines={1} type="s-strong" className={styles.firstColumn} title={attribute.name}>
              {attribute.name}
            </Truncate>
            <WppTypography type="s-body" className={styles.secondColumn}>
              {(Array.isArray(attribute.value) ? attribute.value.join(', ') : attribute.value) || '-'}
            </WppTypography>
          </Flex>
        ))}
        <WppDivider />
        <Flex gap={12} data-testid="project-goals-and-description">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('overview.goals_and_description')}
          </WppTypography>
          <WppTypography className={styles.secondColumn} style={{ wordWrap: 'break-word' }} type="s-body">
            {description || '-'}
          </WppTypography>
        </Flex>
        <WppDivider />
        <Flex gap={12} data-testid="project-id">
          <WppTypography className={styles.firstColumn} type="s-strong">
            Project ID
          </WppTypography>
          <WppTypography type="s-body" className={styles.secondColumn}>
            {id}
          </WppTypography>
        </Flex>
        <Flex gap={12} data-testid="project-creation-date">
          <WppTypography className={styles.firstColumn} type="s-strong">
            {t('common.created_on')}
          </WppTypography>
          <WppTypography type="s-body">
            {formatDate({
              dateString: createdAt,
              dateLocale,
              relativeFormatting: true,
            })}
          </WppTypography>
        </Flex>
      </Flex>

      {isOwnerOrGlobalManage && !isInactive && (
        <div slot="actions">
          <WppActionButton
            slot="trigger-element"
            variant="secondary"
            onClick={() => showProjectEditModal({ project: project })}
          >
            <WppIconEdit slot="icon-start" />
          </WppActionButton>
        </div>
      )}
    </WppCard>
  )
}
