import { WppCard, WppEmptyDataviz, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { ViewMode } from 'gantt-task-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectsTimelineApi } from 'api/projects/queries/useProjectsTimelineApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useToast } from 'hooks/useToast'
import { ProjectsTimelineChart } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineChart'
import { ProjectsTimelineProvider } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineProvider'
import { ProjectsTimelineActionBar } from 'pages/dashboard/components/projectsTimelineView/projectTimelineActionBar/ProjectsTimelineActionBar'
import { projectsTimeline2Gantt } from 'pages/dashboard/components/projectsTimelineView/utils'
import { useHaveAnyAttributes } from 'pages/dashboard/utils/attributes'
import styles from 'pages/project/components/timeline/Timeline.module.scss'
import { ProjectFilter, ProjectOwnership } from 'types/projects/projects'

interface Props {
  filter: ProjectFilter
  setProjectsExist: (exist: boolean) => void
  setFilters: (filter: ProjectFilter) => void
}

export const TimelineSkeleton = () => (
  <Flex className={styles.projectTimelineContainer}>
    <WppSkeleton variant="rectangle" className={styles.timelineSkeleton} />
  </Flex>
)
export const TimelineEmpty = () => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.timelineContainer}>
      <EmptyState
        noRecordsIcon={<WppEmptyDataviz width={120} />}
        title={t('timeline.noData.title')}
        description={t('timeline.noData.description')}
      />
    </Flex>
  )
}

export const ProjectsTimelineView = ({ filter, setFilters, setProjectsExist }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const isFilterActive = Object.values(filter).some(filter => !!filter?.length)
  const { search, ownership, type, workspace, status, tenant, attributes } = filter

  /*
   * @TODO: this should be removed, because now BE calculate empty array like
   *   empty filter and return all items, so when `workspace: []` they shouldn't return anything.
   *   JIRA: WPPLONOP-10970
   * */
  const enabled = !workspace || (Array.isArray(workspace) && !!workspace.length)

  const haveAnyAttributes = useHaveAnyAttributes(attributes)

  const {
    data: timeline,
    isLoading: isTimelineLoading,
    isError: isTimelineError,
  } = useProjectsTimelineApi({
    params: {
      search,
      ownership: ownership === ProjectOwnership.ALL ? undefined : ownership,
      type,
      workspace,
      status,
      tenant,
      attributes: Object.keys(haveAnyAttributes).length ? haveAnyAttributes : undefined,
    },
    enabled,
  })

  useEffect(() => {
    setProjectsExist(!!timeline.length)
  }, [timeline, setProjectsExist])

  useEffect(() => {
    if (isTimelineError) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
    }
  }, [isTimelineError, showToast, t])

  const ganttProjects = useMemo(() => projectsTimeline2Gantt({ timeline }), [timeline])

  const isEmpty = !timeline.length

  if (isTimelineLoading) {
    return <TimelineSkeleton />
  }

  if (isEmpty && !isFilterActive) {
    return <TimelineEmpty />
  }

  const handleSetViewMode = (viewMode: ViewMode) => {
    setFilters({ ...filter, viewMode })
  }

  return (
    <WppCard size="m" className={styles.projectsTimeline}>
      <ProjectsTimelineActionBar filters={filter} setViewMode={handleSetViewMode} />
      {isEmpty && (
        <EmptyState
          title={t('common.no_search_results')}
          filtersApplied={isFilterActive}
          description={t('common.no_results_description')}
          testToken="timeline"
        />
      )}

      {!isEmpty && (
        <ProjectsTimelineProvider timeline={timeline!}>
          <ProjectsTimelineChart projects={ganttProjects} viewMode={filter.viewMode} />
        </ProjectsTimelineProvider>
      )}
    </WppCard>
  )
}
