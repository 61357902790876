import { MayBeNull } from '@wpp-open/core'
import { endOfDay, startOfDay } from 'date-fns'
import { Task as GanttTask, ViewMode } from 'gantt-task-react'

import { getTimelineExpandedState } from 'pages/project/components/timeline/components/taskListTable/utils'
import { Members } from 'types/members/members'
import { TaskDueDate, TaskStatus } from 'types/projects/tasks'
import { isFluidTimeline, Timeline, TimelineTask } from 'types/projects/timeline'
import { PhaseStatus } from 'types/projects/workflow'
import { isPhaseOverdue, isTaskOverdue as isTaskOverdueFn } from 'utils/project'

const Colors = {
  default: 'var(--wpp-primary-color-100)',
  complete: 'var( --wpp-dataviz-color-seq-positive-100)',
  overdue: 'var(--wpp-dataviz-color-seq-negative-100)',
  transparent: 'transparent',
}

export interface TimelineFilters {
  search?: string
  viewMode: ViewMode
  dueDateRanges?: (keyof typeof TaskDueDate)[]
  selectedStatuses?: string[]
  assignee?: string[]
}

export const initialTimelineFilters: TimelineFilters = {
  search: undefined,
  dueDateRanges: [],
  selectedStatuses: [],
  viewMode: ViewMode.Day,
  assignee: [],
}

export const timeline2Gantt = ({
  timeline,
  members,
  withAssignee,
}: {
  timeline: MayBeNull<Timeline>
  members: Members[]
  withAssignee: string[]
}): GanttTask[] => {
  if (!timeline) {
    return []
  }

  const tasksByAssignee: { [key: string]: TimelineTask[] } = withAssignee.reduce(
    (acc, email) => ({
      ...acc,
      [email]: [],
    }),
    {},
  )

  if (isFluidTimeline(timeline)) {
    timeline.tasks?.forEach(task => {
      const assigneeId = task.assignee || 'unassigned'

      if (!withAssignee.includes(assigneeId)) return

      if (!tasksByAssignee[assigneeId]) {
        tasksByAssignee[assigneeId] = []
      }
      tasksByAssignee[assigneeId].push(task)
    })
  } else {
    timeline.phases.forEach(phase => {
      phase.tasks?.forEach(task => {
        const assigneeId = task.assignee || 'unassigned'

        if (!withAssignee.includes(assigneeId)) return

        if (!tasksByAssignee[assigneeId]) {
          tasksByAssignee[assigneeId] = []
        }
        tasksByAssignee[assigneeId].push(task)
      })
    })
  }

  const ganttTasksByAssignee: GanttTask[] = Object.entries(tasksByAssignee)
    .sort((a, b) => (a[1].length >= b[1].length ? -1 : 1))
    .reduce<GanttTask[]>((acc, [email, tasks]) => {
      const assignee = members.find(member => member.email === email) || {
        id: 'unassigned',
        firstname: 'Unassigned',
        lastname: '',
      }

      const startDate = tasks
        .filter(task => !!task.startDate)
        .map(task => task.startDate!)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]

      const endDate = tasks
        .filter(task => !!task.endDate)
        .map(task => task.endDate!)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]

      const isNoDates = !startDate || !endDate
      const projectColor = Colors.default
      const transparentColor = Colors.transparent

      const project: GanttTask = {
        id: assignee.id || 'un',
        name: '',
        start: !isNoDates ? startOfDay(new Date(startDate!)) : new Date(),
        end: !isNoDates ? endOfDay(new Date(endDate!)) : new Date(),

        type: 'project',
        progress: 100,
        hideChildren: !getTimelineExpandedState(assignee.id),
        isDisabled: true,
        styles: {
          backgroundColor: isNoDates ? Colors.transparent : projectColor,
          backgroundSelectedColor: isNoDates ? transparentColor : projectColor,
          progressColor: isNoDates ? transparentColor : projectColor,
          progressSelectedColor: isNoDates ? transparentColor : projectColor,
        },
      }

      return [...acc, project, ...tasks.map(task => createGanttTask(task, project.id))]
    }, [] as GanttTask[])

  if (isFluidTimeline(timeline)) {
    return !!withAssignee.length ? ganttTasksByAssignee : timeline.tasks.map((task): GanttTask => createGanttTask(task))
  }

  const ganttTasks = timeline.phases.reduce<GanttTask[]>((acc, phase) => {
    const isOverdue = isPhaseOverdue(phase)
    const color = phase.status === PhaseStatus.COMPLETED ? Colors.complete : isOverdue ? Colors.overdue : Colors.default

    // project own start date or the earliest task start date
    const startDate = phase.startDate
      ? phase.startDate
      : phase.tasks
          ?.filter(({ startDate }) => !!startDate)
          .map(({ startDate }) => startDate)
          .sort((a, b) => new Date(a!).getTime() - new Date(b!).getTime())[0]

    // project own end date or the latest task start date
    const endDate = phase.endDate
      ? phase.endDate
      : phase.tasks
          ?.filter(({ endDate }) => !!endDate)
          .map(({ endDate }) => endDate)
          .sort((a, b) => new Date(b!).getTime() - new Date(a!).getTime())[0]

    const isNoDates = !startDate || !endDate
    const transparentColor = Colors.transparent

    const project: GanttTask = {
      id: phase.id,
      name: '',
      start: !isNoDates ? startOfDay(new Date(startDate!)) : new Date(),
      end: !isNoDates ? endOfDay(new Date(endDate!)) : new Date(),
      type: 'project',
      progress: 100,
      hideChildren: !getTimelineExpandedState(phase.id),
      isDisabled: true,
      styles: {
        backgroundColor: transparentColor,
        backgroundSelectedColor: isNoDates ? transparentColor : color,
        progressColor: isNoDates ? transparentColor : color,
        progressSelectedColor: isNoDates ? transparentColor : color,
      },
    }

    return [...acc, project, ...phase.tasks?.map(task => createGanttTask(task, project.id))!]
  }, [] as GanttTask[])

  return (!!withAssignee.length ? ganttTasksByAssignee : ganttTasks).concat(
    timeline.externalTasks.map(task => createGanttTask(task)),
  )
}

function createGanttTask(task: TimelineTask, projectId?: string): GanttTask {
  const isNoDates = !task.startDate || !task.endDate
  const isTaskOverdue = isTaskOverdueFn(task)
  const taskColor =
    task.status === TaskStatus.COMPLETED ? Colors.complete : isTaskOverdue ? Colors.overdue : Colors.default
  const transparentColor = Colors.transparent

  return {
    id: task.id,
    project: projectId,
    name: '',
    start: !isNoDates ? startOfDay(new Date(task.startDate!)) : new Date(),
    end: !isNoDates ? endOfDay(new Date(task.endDate!)) : new Date(),

    type: 'task',
    progress: 0,
    isDisabled: isNoDates,

    styles: {
      backgroundColor: isNoDates ? transparentColor : taskColor,
      backgroundSelectedColor: isNoDates ? transparentColor : taskColor,
      progressColor: isNoDates ? transparentColor : taskColor,
      progressSelectedColor: isNoDates ? transparentColor : taskColor,
    },
  }
}
