import {
  WppActionButton,
  WppCard,
  WppIconCopy,
  WppIconEdit,
  WppIconLink,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTypography,
  WppIconTrash,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { useToast } from 'hooks/useToast'
import { showDeleteLinkModal } from 'pages/project/components/canvas/components/deleteLinkModal/DeleteLinkModal'
import { showEditLinkModal } from 'pages/project/components/canvas/components/editLinkModal/EditLinkModal'
import styles from 'pages/project/components/canvas/components/externalLink/ExternalLinkView.module.scss'
import { ExternalLink } from 'types/projects/workflow'

interface Props {
  activityId: string
  link: ExternalLink
  isDisabled: boolean
  isPreview?: boolean
  linkActions?: boolean
  isInactive?: boolean
  variant?: 'primary' | 'secondary'
}
export const ExternalLinkView = ({ activityId, link, isPreview, linkActions, variant, isInactive }: Props) => {
  const { t } = useTranslation()
  const [, copyToClipboard] = useCopyToClipboard()

  const { showToast } = useToast()

  const removeHandler = useCallback(() => showDeleteLinkModal({ activityId, link }), [activityId, link])
  const copyToClipboardHandler = useCallback(
    () => {
      copyToClipboard(link.url)
      showToast({ type: 'success', message: t('project.links_modal.toast_copy_to_clipboard')! })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copyToClipboard],
  )

  return (
    <WppCard
      size="s"
      className={clsx(styles.externalLinkContainer, { [styles.preview]: isPreview })}
      variant={variant || 'primary'}
    >
      <Flex align="center" data-testid="activity-external-link">
        <Link to={link.url} className={styles.externalLink} key={link.url} target="_blank" rel="noopener noreferrer">
          <Flex align="center" gap={8}>
            <WppIconLink slot="icon-start" data-testid="activity-external-link-icon" />

            <WppTypography type="s-body" className={styles.textBreak} data-testid="activity-external-link-url">
              {link.alias || link.url}
            </WppTypography>
          </Flex>
        </Link>

        {!isPreview && linkActions && !isInactive && (
          <div className={styles.externalLinkMenu}>
            <WppMenuContext dropdownConfig={{ appendTo: () => document.body }}>
              <WppActionButton slot="trigger-element" variant="secondary" data-testid="activity-external-link-menu">
                <WppIconMore slot="icon-start" direction="horizontal" />
              </WppActionButton>
              <div>
                <WppListItem onWppChangeListItem={copyToClipboardHandler} data-testid="copy-link-action">
                  <WppIconCopy slot="left" />
                  <p slot="label">{t('project.canvas.copy_link')}</p>
                </WppListItem>
                {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                <div style={{ display: isInactive ? 'none' : 'block' }}>
                  <WppListItem
                    onWppChangeListItem={() => showEditLinkModal({ activityId, link })}
                    data-testid="edit-link-action"
                  >
                    <WppIconEdit slot="left" />
                    <p slot="label">{t('common.btn_edit')}</p>
                  </WppListItem>
                  <WppListItem onWppChangeListItem={removeHandler} data-testid="delete-link-action">
                    <WppIconTrash slot="left" />
                    <p slot="label">{t('common.btn_delete')}</p>
                  </WppListItem>
                </div>
              </div>
            </WppMenuContext>
          </div>
        )}
      </Flex>
    </WppCard>
  )
}
