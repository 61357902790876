import { facadeApi } from 'api'

interface Params {
  groupId: string
  search?: string
  limit?: number
  offset?: number
}

interface GroupUser {
  id: string
  email: string
  username: string
  group_id: string
  group_name?: string
  first_name?: string
  last_name?: string
  created_at?: string
  updated_at?: string
}

export const fetchGroupMembersApi = ({ groupId, search, offset = 0, limit = 20 }: Params) =>
  facadeApi.get<GroupUser[]>(`/az/groups/${groupId}/users`, {
    params: {
      offset,
      limit,
      ...(search?.length && { search }),
    },
  })
