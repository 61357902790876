import { WppListItem, WppSelect, WppTypography } from '@platform-ui-kit/components-library-react'
import { UserDetails } from '@wpp-open/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FACADE_MAX_ITEMS } from 'api'
import { useFetchGroupMembersApi } from 'api/tenant/queries/useFetchGroupMembersApi'
import { useUsersListApi } from 'api/users/queries/useUsersListApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { useField } from 'hooks/form/useField'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useToast } from 'hooks/useToast'
import { GroupAssignment } from 'pages/components/projectModal/components/membersGroups/utils'
import { ProjectRole } from 'types/permissions/permissions'
import { fullName } from 'utils/common'

interface Props {
  index: number
  groupId: string
  'data-testid'?: string
}

export const SelectMemberInGroup = ({ groupId, index, 'data-testid': dataTestId }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const {
    field: { value, onChange, onBlur },
  } = useField({
    name: `groupAssignments.${index}`,
  })

  const [usersSearch, setUsersSearch] = useState('')
  const search = useDebouncedValue(usersSearch.trim(), 300)

  const useMembers = !search
  const {
    data: groupMembers,
    isLoading: isGroupMembersLoading,
    isError: isGroupMembersError,
  } = useFetchGroupMembersApi({
    params: { groupId, search, limit: FACADE_MAX_ITEMS },
    enabled: useMembers,
  })

  const {
    data: allUsers,
    isLoading: isAllUsersLoading,
    isError: isAllUsersError,
  } = useUsersListApi({
    params: { search },
    enabled: !useMembers,
  })

  const isLoading = isGroupMembersLoading || isAllUsersLoading
  const users = useMembers ? groupMembers : allUsers.data
  const isEmpty = !isLoading && !users.length

  useEffect(() => {
    const isError = isGroupMembersError || isAllUsersError
    if (isError) {
      showToast({ type: 'error', message: t('modals.create_project.members_groups.load_error')! })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUsersError, isGroupMembersError])

  return (
    <WppSelect
      size="s"
      type="single"
      placeholder={t('modals.create_project.members_groups.member_placeholder')}
      value={value}
      displayValue={value?.username}
      dropdownConfig={{
        onHidden: onBlur,
      }}
      data-testid={dataTestId}
      infinite
      loading={isLoading}
      infiniteLastPage={true}
      withSearch
      onWppSearchValueChange={(e: CustomEvent) => setUsersSearch(e.detail)}
    >
      {isEmpty ? (
        <WppTypography type="s-body">{t('common.search_no_results')}</WppTypography>
      ) : (
        users.map(user => (
          <WppListItem
            key={user.id}
            value={user.email}
            onWppChangeListItem={e => {
              // `onWppChange` handler in `WppSelect` will not handle click on the selected item
              const isDeselecting = value?.email === e.detail.value
              if (isDeselecting) {
                onChange(void 0)
                return
              }

              onChange({
                index,
                groupId,
                email: user.email,
                username: fullName(user.firstname, user.lastname),
                role: ProjectRole.VIEWER,
                avatarUrl: (user as UserDetails).avatarUrl,
              } as GroupAssignment)
            }}
          >
            <span slot="label">{fullName(user.firstname, user.lastname)}</span>

            <span slot="caption">{user.email ?? '-'}</span>
            <Avatar
              size="s"
              name={fullName(user.firstname, user.lastname)}
              src={(user as UserDetails).avatarUrl ?? ''}
              slot="left"
            />
          </WppListItem>
        ))
      )}
    </WppSelect>
  )
}
