import { WppDatepicker } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { format } from 'date-fns'
import { FunctionComponent, useMemo, useRef } from 'react'

import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import styles from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline.module.scss'
import { DATE_FORMAT } from 'utils/dateFormat'

const dateFormat = DATE_FORMAT.DD_MM_YYYY

interface Props {
  startDate?: string
  endDate?: string
  onChange: (dates: Date[]) => void
  defaultOverdueColor?: boolean
  calendarIcon?: FunctionComponent
  hideTooltip?: boolean
  resolved: boolean
}

export const SelectDateInline = ({
  startDate,
  endDate,
  resolved,
  onChange,
  defaultOverdueColor,
  calendarIcon,
  hideTooltip,
}: Props) => {
  const instanceRef = useRef(0)
  const innerRef = useRef<MayBeNull<HTMLWppDatepickerElement>>(null)

  const mapValue = useMemo(() => {
    instanceRef.current = instanceRef.current + 1
    if (!startDate?.length || !endDate?.length) return undefined
    return [startDate, endDate].map(date => format(new Date(date), dateFormat))
  }, [startDate, endDate])

  return (
    <label
      className={clsx(styles.dateEmptyLabel, { [styles.dateLabel]: startDate || endDate })}
      htmlFor="datepicker"
      onClick={() => {
        innerRef.current?.setFocus()
      }}
    >
      <WppDatepicker
        key={instanceRef.current}
        ref={innerRef}
        className={styles.datePickerInline}
        value={mapValue}
        range
        locale={{ dateFormat }}
        onClick={e => e.stopPropagation()}
        onWppChange={e => onChange(e.detail.date as Date[])}
        onWppDateClear={() => {
          onChange([])
          // hide datepicker with suppressing hiding error (caused by `WppDatepicker` wrapper around `AirDatepicker`)
          innerRef.current
            ?.getInstance()
            .then(airPicker => airPicker?.hide())
            .catch(() => void 0)
        }}
        data-testid={`inline-datepicker-${mapValue}`}
      />
      <Calendar
        startDate={startDate}
        endDate={endDate}
        defaultOverdueColor={defaultOverdueColor}
        customIcon={calendarIcon}
        hideTooltip={hideTooltip}
        resolved={resolved}
      />
    </label>
  )
}
