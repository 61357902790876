import { createUseQuery } from 'api/common/createUseQuery'
import { fetchAttributesByProjectApi } from 'api/projects/fetchers/fetchAttributesByProjectApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useFetchProjectAttributesApi = createUseQuery({
  queryKey: ApiQueryKeys.PROJECT_ATTRIBUTES,
  fetcher: fetchAttributesByProjectApi,
  selector: res => {
    return res?.data.data.sort((a, b) => (a.orderNumber! > b.orderNumber! ? 1 : -1)) || []
  },
})
