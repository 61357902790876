import { WppButton, WppInput, WppListItem, WppSelect, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { useCreateApplication } from 'api/canvas/mutation/useCreateApplication'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { CanvasItemType } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useNativeAppsErrors } from 'hooks/application/useAppErrors'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import styles from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal.module.scss'
import { showAppsCreateIssuesModal } from 'pages/project/components/canvas/components/appPikerModal/appsCreateIssuesModal/AppsCreateIssuesModal'
import { NativeApps } from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import {
  nativeAppsCategoryOptions,
  nativeAppsCommercialModelOptions,
} from 'pages/project/components/canvas/components/appPikerModal/utils'
import { START_POSITION, ITEM_OFFSET } from 'pages/project/components/canvas/fluidCanvas/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'
import { ProcessType } from 'types/projects/projects'
import { makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface BaseProps extends NiceModalWrappedProps {
  projectId: string
  selectedCanvas: ProcessType
  isTemplate?: boolean
}

export interface PhaseId extends BaseProps {
  activityId?: string
  phaseId: string
}

export interface ActivityId extends BaseProps {
  phaseId?: string
  activityId: string
}

export type Props = PhaseId | ActivityId

const AppPickerModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  projectId,
  selectedCanvas,
  phaseId,
  activityId,
  isTemplate,
}: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { mutateAsync: createApplication } = useCreateApplication()
  const projectContext = useProject()
  const { trackItemAdd } = useWorkflowEditAnalytics()

  const [{ isCreating, selectedApps }, setState] = useSetState({
    isCreating: false,
    selectedApps: [] as SelectedApp[],
  })

  const [{ search, ...filters }, setFilters] = useSetState({
    search: '',
    categories: [] as NativeAppsCategory[],
    commercialModel: '' as NativeAppsCommercialModel,
  })

  const setSearchDebounced = useDebounceFn((search: string) => {
    setFilters(filters => ({ ...filters, search: search.trim() }))
  }, 300)

  const appsErrors = useNativeAppsErrors(selectedApps)
  const appErrorsList = useMemo(() => Object.values(appsErrors).flat(), [appsErrors])

  const createItems = async (apps: SelectedApp[], ignoreErrors?: boolean) => {
    const hasErrors = !!appErrorsList.length
    const appsCount = apps.reduce((acc, app) => acc + app.versions.length, 0)

    if (hasErrors && !ignoreErrors && !isTemplate) {
      showAppsCreateIssuesModal({
        appsCount,
        appsErrors: appErrorsList,
        onAddApps: () => createItems(apps, true),
      })
      return
    }

    setState({ isCreating: true })

    try {
      let createdResponse: Awaited<ReturnType<typeof createApplication>>

      if (selectedCanvas === ProcessType.FLUID) {
        let x = START_POSITION.x
        let y = START_POSITION.y
        const fluidApps = apps.flatMap(({ id, type, versions }) => {
          x += ITEM_OFFSET.x
          y += ITEM_OFFSET.y

          return versions.map(version => ({
            referenceData: {
              id,
              type,
              versionId: version.id,
            },
            coordinateX: x,
            coordinateY: y,
          }))
        })

        createdResponse = await createApplication({
          activityId: activityId || undefined,
          projectId,
          applications: fluidApps,
        })
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
      } else {
        const applications = apps.flatMap(({ id, type, versions }) => {
          return versions.map(version => ({
            referenceData: {
              id,
              type,
              versionId: version.id,
            },
          }))
        })
        if (activityId) {
          createdResponse = await createApplication({ activityId, projectId, applications })
        } else {
          createdResponse = await createApplication({ phaseId, projectId, applications })
        }
        queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR])
      }

      const createdApps = createdResponse.data.applications
      const toastMsg = appsCount === 1 ? 'project.canvas.toast.add_an_app' : 'project.canvas.toast.add_2_or_more_apps'
      const query = appsCount === 1 ? makeStringShorter(createdApps[0].name) : appsCount
      showToast({ type: 'success', message: t(toastMsg, { query }) })

      if (projectContext) {
        trackItemAdd({
          type: CanvasItemType.APPLICATION,
          numberOfApps: appsCount,
        })
      }

      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_add_app', { count: appsCount }),
      })
      setState({ isCreating: false })
      console.error(e)
    }
  }

  const amountOfAppInstances = selectedApps.reduce((acc, app) => acc + app.versions.length, 0)

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="2xl"
      data-testid="add-app-modal"
    >
      <WppTypography slot="header" type="2xl-heading" data-testid="add-app-modal-name">
        {t('modals.add_from_marketplace.title')}
      </WppTypography>
      <Flex slot="body" direction="column" gap={25} className={styles.container}>
        <Flex direction="column" gap={16}>
          <Flex gap={16}>
            <WppInput
              style={{ flexGrow: 1 }}
              name="search"
              onWppChange={event => setSearchDebounced(event.detail.value || '')}
              size="s"
              type="search"
              placeholder={t('product.search_in_marketplace')!}
              data-testid="app-search-field"
            />
          </Flex>
          <Flex gap={16}>
            <WppSelect
              size="s"
              type="multiple"
              placeholder="Category"
              value={filters.categories}
              className={styles.select}
              onWppChange={e => setFilters({ categories: e.detail.value as NativeAppsCategory[] })}
            >
              {nativeAppsCategoryOptions.map(({ value, label }) => (
                <WppListItem key={value} value={value}>
                  <p slot="label">{label}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <div className={styles.divider} />
            <WppSelect
              size="s"
              placeholder="Commercial model"
              className={styles.select}
              value={filters.commercialModel}
              onWppChange={e =>
                setFilters({
                  commercialModel: (e.detail.value === 'none' ? '' : e.detail.value) as NativeAppsCommercialModel,
                })
              }
            >
              <WppListItem key="none" value="none">
                <p slot="label">None</p>
              </WppListItem>
              {nativeAppsCommercialModelOptions.map(({ value, label }) => (
                <WppListItem key={value} value={value}>
                  <p slot="label">{label}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </Flex>
        </Flex>

        <NativeApps
          search={search}
          filters={filters}
          selectedApps={selectedApps}
          onSelectApp={apps => setState({ selectedApps: apps })}
        />
      </Flex>

      <Flex slot="actions" justify="end" align="center" gap={12}>
        <Flex gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('modals.add_from_marketplace.btn_cancel')}
          </WppButton>
          <WppButton
            variant="primary"
            size="m"
            onClick={() => createItems(selectedApps)}
            disabled={!amountOfAppInstances}
            loading={isCreating}
            data-testid="add-apps-button"
          >
            {amountOfAppInstances > 1
              ? t('modals.add_from_marketplace.btn_add_items', { count: amountOfAppInstances })
              : t('modals.add_from_marketplace.btn_add_item')}
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showAppPickerModal } = createProjectModal<Props>(AppPickerModal, 'app-picker-modal')
