import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppInput } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SelectOsGroup } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroup'
import styles from 'pages/template/tasks/TemplateTasks.module.scss'
import { TemplateTasksFilter } from 'types/templates/tasks'

interface TemplateTasksFiltersProps {
  filters: TemplateTasksFilter
  setFilters: React.Dispatch<React.SetStateAction<TemplateTasksFilter>>
  setSearchDebounced: (e: WppInputCustomEvent<InputChangeEventDetail>) => void
}

export const TemplateTasksFilters = ({ filters, setFilters, setSearchDebounced }: TemplateTasksFiltersProps) => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.filtersContainer}>
      <Flex gap={16}>
        <WppInput
          size="s"
          name="search"
          placeholder={t('dashboard.field_search_placeholder')!}
          onWppChange={setSearchDebounced}
          type="search"
          data-testid="template-tasks-search"
          className={styles.searchInput}
        />

        <SelectOsGroup
          value={filters.group}
          className={styles.groupSelect}
          data-testid="filter-os-group"
          onChange={async group => {
            setFilters(filters => ({ ...filters, group }))
          }}
        />
      </Flex>
    </Flex>
  )
}
