import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppIconCalendar, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { format, isPast, endOfDay } from 'date-fns'
import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/Phase.module.scss'
import { DATE_FORMAT } from 'utils/dateFormat'

interface Props {
  resolved: boolean
  startDate?: string
  endDate?: string
  hideIcon?: boolean
  size?: TypographyType
  defaultOverdueColor?: boolean
  customIcon?: FunctionComponent
  hideTooltip?: boolean
}

export const Calendar = ({
  startDate,
  endDate,
  resolved,
  hideIcon,
  defaultOverdueColor,
  size,
  customIcon,
  hideTooltip,
}: Props) => {
  const { t } = useTranslation()

  const highlight = useMemo(
    () => !defaultOverdueColor && !resolved && endDate && isPast(endOfDay(new Date(endDate))),
    [defaultOverdueColor, resolved, endDate],
  )

  const isTheSameDay = useMemo(
    () =>
      startDate &&
      endDate &&
      format(new Date(startDate), DATE_FORMAT.DD_LLL_YYYY) === format(new Date(endDate), DATE_FORMAT.DD_LLL_YYYY),
    [startDate, endDate],
  )

  const Icon = customIcon ?? WppIconCalendar

  if (startDate && endDate) {
    return (
      <WppTooltip
        header={t('calendar.due_date')!}
        text={format(new Date(endDate), DATE_FORMAT.DD_LLL_YYYY)}
        config={{ trigger: hideTooltip ? 'manual' : 'mouseenter', hideOnClick: false }}
      >
        <Flex direction="row" gap={6} align="center">
          {!hideIcon && (
            <Icon
              className={clsx({
                [styles.default]: !size && !highlight,
                [styles.grey]: size === 'xs-body' && !highlight,
                [styles.passed]: highlight,
              })}
            />
          )}
          <WppTypography
            data-testid="due-date-text"
            className={clsx({
              [styles.defaultText]: !size && !highlight,
              [styles.grey]: size === 'xs-body' && !highlight,
              [styles.passed]: highlight,
            })}
            type={size ? size : 's-strong'}
          >
            {isTheSameDay ? (
              format(new Date(startDate), DATE_FORMAT.D_LLL)
            ) : (
              <>
                {`${format(new Date(startDate), DATE_FORMAT.D_LLL)} - ${format(new Date(endDate), DATE_FORMAT.D_LLL)}`}
              </>
            )}
          </WppTypography>
        </Flex>
      </WppTooltip>
    )
  }

  if (startDate || endDate) {
    const date = startDate || endDate
    return (
      <WppTooltip
        header={`${startDate ? t('calendar.start') : t('calendar.due')} ${t('calendar.date')}`}
        text={format(new Date(date!), DATE_FORMAT.DD_LLL_YYYY)}
      >
        <Flex direction="row" gap={6} align="center">
          {!hideIcon && <Icon color="var(--wpp-primary-color-500)" />}
          <WppTypography className={styles.calendar} type={size ? size : 's-strong'}>
            {format(new Date(date!), DATE_FORMAT.DD_LLL_YYYY)}
          </WppTypography>
        </Flex>
      </WppTooltip>
    )
  }

  return (
    <WppTooltip header={t('calendar.due_date')!} text={t('calendar.not_set')!}>
      {!hideIcon && <Icon />}
    </WppTooltip>
  )
}
